import React, { useState, useRef, useEffect } from "react";
import { Button, Typography, Space } from "antd";
import { FaStop } from "react-icons/fa6";
import { DownloadOutlined, RedoOutlined } from "@ant-design/icons";
import { Col, Row, Select } from "antd";
import { VideoCameraOutlined } from "@ant-design/icons";

import styles from "./VideoRecorder.module.scss";

const mimeType = 'video/webm; codecs="opus,vp8"';
const { Text } = Typography;

const VideoRecorder = () => {
  const [permission, setPermission] = useState(false);
  const mediaRecorder = useRef(null);
  const liveVideoFeed = useRef(null);
  const stream = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [videoChunks, setVideoChunks] = useState([]);

  function getSupportedMimeTypes() {
    const possibleTypes = [
      'video/webm;codecs=av1,opus',
      'video/webm;codecs=vp9,opus',
      'video/webm;codecs=vp8,opus',
      'video/webm;codecs=h264,opus',
      'video/mp4;codecs=h264,aac',
    ];
    return possibleTypes.filter((mimeType) => {
      return MediaRecorder.isTypeSupported(mimeType);
    });
  }
  const [selectedFormat, setSelectedFormat] = useState(getSupportedMimeTypes()[2]);

  const getCameraPermission = async () => {
    setRecordedVideo(null);
    if ("MediaRecorder" in window) {
      try {
        const videoConstraints = {
          audio: false,
          video: true,
        };
        const audioConstraints = { audio: true };

        const audioStream = await navigator.mediaDevices.getUserMedia(audioConstraints);
        const videoStream = await navigator.mediaDevices.getUserMedia(videoConstraints);

        setPermission(true);

        const combinedStream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...audioStream.getAudioTracks(),
        ]);

        stream.current = combinedStream;

        liveVideoFeed.current.srcObject = videoStream;
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };
 
  const startRecording = async () => {
    setRecordingStatus("recording");
    const media = new MediaRecorder(stream.current, { selectedFormat });
    mediaRecorder.current = media;
    mediaRecorder.current.start();

    let localVideoChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localVideoChunks.push(event.data);
    };

    setVideoChunks(localVideoChunks);
  };

  const stopRecording = () => {
    setPermission(true);
    setRecordingStatus("inactive");
    mediaRecorder.current.stop();

    mediaRecorder.current.onstop = () => {
      const videoBlob = new Blob(videoChunks, { type: selectedFormat });
      const videoUrl = URL.createObjectURL(videoBlob);

      setRecordedVideo(videoUrl);
      setVideoChunks([]);

      if (stream.current) {
        stream.current.getTracks().forEach((track) => track.stop());
      }
    };
  };

  return (
    <>
      <Row
        justify="center"
        align="middle"
      >
        <Col
          span={24}
          className="px-5 mt-4"
        >
          <Select
            defaultValue={getSupportedMimeTypes()[2]}
            style={{ width: 280 }}
            onChange={(value) => {
              setSelectedFormat(value);
            }}
            options={getSupportedMimeTypes().map(item => ({ value: item, label: item }))}
          />
        </Col>
        <Col justify="center" align="middle"
          span={24}
          className="d-inline-flex justify-center text-center align-center"
        >
          <div>
            <div>
              {!recordedVideo ? (
                <video ref={liveVideoFeed} autoPlay className={styles.livePlayer}></video>
              ) : null}
              {recordedVideo ? (
                <div className={styles.recordedPlayer}>
                  <video className="recorded" src={recordedVideo} controls></video>
                </div>
              ) : null}
            </div>
            {(!recordedVideo) && permission && recordingStatus === "inactive" ? (
              <Text strong>PRESS <span className="text-danger">REC</span> WHEN READY</Text>
            ) : null}
            {recordingStatus === "recording" ? (
              <Text strong>PRESS <span className="text-danger">STOP</span></Text>
            ) : null}
            <div className="video-controls mb-3 mx-auto">
              <Space wrap>
                {!permission ? (
                  <Button type="primary" className={styles.videoButton} onClick={getCameraPermission} icon={<VideoCameraOutlined />} />
                ) : null}
                {(!recordedVideo) && permission && recordingStatus === "inactive" ? (
                  <Button type="primary" className={styles.videoButton} onClick={() => startRecording()} disabled={recordingStatus === "recording"} icon={<VideoCameraOutlined />} />
                ) : null}
                {recordingStatus === "recording" && (
                  <Button danger onClick={stopRecording} className={styles.videoButton} >
                    <FaStop fontSize={20} />
                  </Button>
                )}
                <Space size="large" >
                  {recordedVideo && permission ? (
                    <Button type="primary" className={styles.videoButton} onClick={() => getCameraPermission()} icon={<RedoOutlined />} />
                  ) : null}
                  {recordedVideo && (
                    <Button className={styles.videoButton} type="link" icon={<DownloadOutlined fontSize={20} />} download href={recordedVideo}  >Download
                    </Button>
                  )}
                </Space>
              </Space>
            </div>
          </div>
        </Col>

      </Row>

    </>
  );
};

export default VideoRecorder;
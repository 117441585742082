// Deliveries
const getAllStatus = [
    { value: 0, label: "All" },
    { value: 1, label: "Processing" },
    { value: 2, label: "Complete" },
    { value: 3, label: "Cancel" },
    { value: 4, label: "Return" },
];

const getAllActualStatus = getAllStatus.slice(1);

const getAllSource = ['FMBS', 'IWATCH', 'BUSINESS', 'RE', 'SE', 'SAMEXPERT'];
const getAllDevice = ['S1/38MM', 'S1/42MM', 'S2/38MM', 'S2/42MM', 'S3/38MM', 'S3/42MM', 'S4/40MM', 'S4/44MM', 'S5/40MM', 'S5/44MM', 'SE/40MM', 'SE/44MM', 'S6/40MM', 'S6/44MM', 'S7/41MM', 'S7/45MM'];

// Orders
const getAllOrderStatus = [
    { value: null, label: "All", status: "", color: '' },
    { value: 1, label: "New", status: "purple", color: 'purple' },
    { value: 2, label: "Processing", status: "gold", color: 'gold' },
    { value: 9, label: "Processing - drop-off", status: "gold", color: 'gold' },
    { value: 10, label: "Ready to collect", status: "gold", color: 'gold' },
    { value: 11, label: "Device collected", status: "success", color: 'success' },
    { value: 3, label: "Complete", status: "blue", color: 'blue' },
    { value: 4, label: "Review Requested", status: "success", color: 'green' },
    { value: 5, label: "Archive", status: "error", color: 'red' },
    { value: 6, label: "WFC", status: "cyan", color: 'cyan' },
    { value: 7, label: "Cancelled", status: 'default', color: '#ccc' },
    { value: 8, label: "Return", status: "default", color: '#000' },
    { value: 12, label: "Escalated", status: "default", color: '#000' },
];

const statusRemovableList = [3, 4, 5, 7];
const filteredOrderStatus = getAllOrderStatus.filter(element => !statusRemovableList.includes(element.value));

// WFC
const getAllWFCStatus = [
    { value: null, label: "All", status: "", color: '' },
    { value: 1, label: "Open", status: "error", color: "red" },
    { value: 2, label: "Pending", status: "gold", color: "gold" },
    { value: 3, label: "Invoice", status: "purple", color: "purple" },
    { value: 4, label: "Manager", status: "default", color: "#000" },
    { value: 5, label: "Solved", status: "blue", color: "blue" },
    { value: 6, label: "Close", status: "success", color: "green" },
];

// Pay
const getAllPayStatus = [
    { value: 0, label: "Un Paid", color: "error" },
    { value: 1, label: "Paid", color: "success" },
];

// Pay type
const getAllPayType = [
    { value: 0, label: "Diagnostic" },
    { value: 1, label: "Paypal" },
    { value: 2, label: "Klarna" },
    { value: 3, label: "Stripe" },
];

// Passcode Option
const getAllPassCodeOption = (hasPassCode = false) => ([
    { value: 1, label: "I will provide the passcode", description: (hasPassCode ? "I Entered the passcode when I placed the order." : "I will send the passcode with my package.") },
    { value: 2, label: "I will send the device unlocked", description: "Back up your device and remove the passcode, or reset it to factory settings." },
    { value: 3, label: "I will activate Maintenance Mode on my Samsung", description: "If available, activate Maintenance Mode to protect your personal data while allowing us to perform the necessary repairs.", 'content': 'Select Settings from your device > Battery and device care > Maintenance Mode > Turn Maintenance Mode on'},
    { value: 4, label: "Proceed without a passcode / My device is not accessible", description: "We will complete the repair without testing the device before or after. Please note that we cannot take responsibility for any issues we are unable to test." },
])

const getStatusByValue = (id) => (getAllStatus.find(({ value }) => (value === id)));
const getOrderStatusByValue = (id) => ((getAllOrderStatus.slice(1)).find(({ value }) => (value === id)));
const getWFCStatusByValue = (id) => (getAllWFCStatus.find(({ value }) => (value === id)));
const getPayStatusByValue = (id) => (getAllPayStatus.find(({ value }) => (value === id)));
const getPayTypeByValue = (id) => (getAllPayType.find(({ value }) => (value === id)));
const getPassCodeOptionByValue = (id, hasPassCode) => (getAllPassCodeOption(hasPassCode).find(({ value }) => (value === id)));

export {
    getAllStatus,
    getStatusByValue,
    getAllActualStatus,
    getAllSource,
    getAllDevice,
    getAllOrderStatus,
    filteredOrderStatus,
    getOrderStatusByValue,
    getAllPayStatus,
    getPayStatusByValue,
    getAllPayType,
    getPayTypeByValue,
    getAllWFCStatus,
    getWFCStatusByValue,
    getAllPassCodeOption,
    getPassCodeOptionByValue,
};
import { Button, Col, Drawer, Form, Input, InputNumber, Row, Space, message } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import DirectOrdersApi from '../../../../apis/order-management/direct-order/DirectOrdersApi';

const { useForm } = Form;

export default function UpdatePayment({ visible, handleCancel, activeOrderId, setKey, setActiveKey, activeOrder, showReceiptPrintModal }) {
    const baseUrl = useSelector((state) => state.auth.base_url);
    const [form] = useForm();
    const amountInputRef = useRef(null);

    const [loadingObj, setLoadingObj] = useState({
        previewLoading: false,
        sendLoading: false,
    });

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                if (amountInputRef.current) {
                    amountInputRef.current.focus();
                }
            }, 300);
        }
    }, [visible]);

    return (
        <Drawer
            open={visible}
            title="Add Payment"
            placement="left"
            onClose={handleCancel}
            width={600}
            destroyOnClose
            extra={
                <Space>
                    <Button
                        type="default"
                        onClick={() => {
                            form.resetFields();
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="default"
                        onClick={() => {
                            handleCancel();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        loading={loadingObj.sendLoading}
                        onClick={() => {
                            // not allow to save if amount is 0
                            if (form.getFieldValue('amount') === 0) {
                                form.setFields([
                                    {
                                        name: 'amount',
                                        errors: ['Amount should be greater than 0'],
                                    },
                                ]);
                                return;
                            }

                            setLoadingObj((prevState) => ({ ...prevState, sendLoading: true }));
                            form
                                .validateFields()
                                .then(async (values) => {
                                    values.OrderId = activeOrderId;
                                    const { data: orderData, success } = await DirectOrdersApi.createNewPayment(baseUrl, values);

                                    // Update the order list
                                    setKey((prevKey) => prevKey + 1);

                                    // Update the order details
                                    setActiveKey((prevKey) => prevKey + 1);

                                    message.success(success);
                                    handleCancel();
                                    setLoadingObj((prevState) => ({ ...prevState, sendLoading: false }));
                                    form.resetFields();

                                    // Open receipt print modal if due amount is 0
                                    if (orderData?.due_amount === 0) {
                                        showReceiptPrintModal(true);
                                    }
                                })
                                .catch((info) => {
                                    setLoadingObj((prevState) => ({ ...prevState, sendLoading: false }));
                                });
                        }}
                    >
                        Save
                    </Button>
                </Space>
            }
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                        name="form_in_modal"
                        initialValues={{
                            description: 'Balance paid on collection',
                            amount: activeOrder?.due_amount,
                        }}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="description" label="Description:">
                                    <Input placeholder="description" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="amount"
                                    label="Amount:"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Amount!',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        className="w-100"
                                        size="large"
                                        formatter={(value) => `£${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        placeholder="Amount"
                                        ref={amountInputRef}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Drawer>
    );
}

import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Button, Col, message, Row, Modal, Spin, AutoComplete, Form, Input, Select, Space } from 'antd';

import OrdersApi from '../../apis/order-management/repair-order/OrdersApi';
import PostcodeApi from '../../apis/postcode/PostcodeApi';
import RecycleOrdersApi from '../../apis/order-management/recycle-order/RecycleOrdersApi';

const { Option } = Select;
const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 24 },
  layout: "vertical"
};

export default function EditAddressFields({ isEditAddressModalOpen, setIsEditAddressModalOpen, setActiveKey, activeOrder, baseUrl, editAddressType, orderType }) {
  const [form] = Form.useForm();

  const addressAutoCompleteRef = useRef(null);

  const [searchValue, setSearchValue] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    setIsEditAddressModalOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setAddresses([]);
    setIsEditAddressModalOpen(false);
  };

  const handleSearch = async (value) => {
    setLoading(true);
    setSearchValue(value);

    try {
      const processedAddress = await PostcodeApi.autocompleteAddress(value);
      if (!processedAddress)
        throw new Error('No results found');

      setAddresses(processedAddress);
    } catch (error) {
      message.error(error.message || 'Failed to fetch addresses');
      setAddresses([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = async (address) => {
    setSearchValue(address);
    const { id } = addresses.find((option) => option.address === address);

    try {
      if (!id)
        throw new Error('No address found');

      const {
        line_1,
        line_2,
        line_3,
        line_4,
        postcode,
        town_or_city
      } = await PostcodeApi.getAddressById(id);

      form.setFieldsValue({
        address_line1: line_1,
        address_line2: line_2,
        address_line3: [line_3, line_4].filter(Boolean).join(', '),
        postcode: postcode,
        city: town_or_city,
        country: 1,
      });

    } catch (error) {
      message.error(error.message || 'Failed to fetch address details');
      setAddresses([]);
    }
  };

  const options = useMemo(() => addresses.map((suggestion) => ({
    id: suggestion.id,
    value: suggestion.address,
  })), [addresses]);

  const onFinish = async (values) => {
    setIsEditAddressModalOpen(false);
    setAddresses([]);
    setSearchValue('');

    try {
      let response;

      values.company_id = (activeOrder?.user?.company?.id) ?? null;

      if (orderType === "repair" || orderType === "collection") {
        response = await OrdersApi.updateAddress(baseUrl, activeOrder.id, editAddressType, values);
      } else if (orderType === "recycle") {
        response = await RecycleOrdersApi.updateAddress(baseUrl, activeOrder?.id, editAddressType, values);
      }

      if (response) {
        message.success('Address updated successfully');

        setActiveKey(prevState => prevState + 1);
        form.resetFields();
        form.setFieldsValue(values); // set form values to updated values, so avoid issues with multiple updates
      }
    } catch (error) {
      message.error(error.message || 'Failed to update address');
    }
  };

  const addressMapHandler = (obj) => {
    return {
      find_address: '',
      address_line1: obj.address,
      address_line2: obj.address_alt,
      address_line3: '',
      city: obj.city,
      postcode: obj.postal_code,
      country: { key: 1, label: 'United Kingdom' }
    };
  };

  const initialValues = useMemo(() => {
    const getAddress = (address) => addressMapHandler(address || {});

    if (orderType === "repair") {
      switch (editAddressType) {
        case "collection":
          return getAddress(JSON.parse(activeOrder?.collection_address));
        case "shipping":
          return getAddress(activeOrder);
        case "billing":
          return getAddress(activeOrder?.customer_address || activeOrder?.user?.company);
        default:
          return {};
      }
    } else if (orderType === "recycle") {
      switch (editAddressType) {
        case "shipping":
          return getAddress(activeOrder?.user?.postal_code ? activeOrder?.user : activeOrder);
        case "billing":
          return getAddress(activeOrder?.customer_address || activeOrder?.user?.company);
        default:
          return {};
      }
    }

    return {};
  }, [activeOrder, editAddressType, orderType]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <Modal
      title="Edit Address"
      open={isEditAddressModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={(
        <Space>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="primary" form="edit-address" key="submit" htmlType="submit">Update</Button>
          <Button type="primary" onClick={() => addressAutoCompleteRef.current.focus({ cursor: 'start' })}>Focus</Button>
        </Space>
      )}
      destroyOnClose
      afterOpenChange={(open) => {
        if (open && addressAutoCompleteRef.current) {
          addressAutoCompleteRef.current.focus({
            cursor: 'start',
          });
        }
      }}
    >
      <Form
        {...layout}
        form={form}
        id="edit-address"
        name="edit-address"
        onFinish={onFinish}
        className='py-3 px-0'
        initialValues={initialValues}
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Row gutter={[24, 0]}>
              <Col span={24}>
                <Form.Item
                  rules={[{ required: false }]}
                  label="Find an Address"
                  className='border px-3 py-3'
                  name="find_address"
                >
                  <AutoComplete
                    ref={addressAutoCompleteRef}
                    value={searchValue}
                    onSearch={handleSearch}
                    onSelect={handleSelect}
                    placeholder="23 Whitechapel Road, London"
                    notFoundContent={loading ? <Spin size="small" /> : 'No results found'}
                    options={options}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="address_line1" className='mb-3' >
                  <Input placeholder="Address Line 1" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="address_line2" className='mb-3' >
                  <Input placeholder="Address Line 2" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="address_line3" className='mb-3' >
                  <Input placeholder="Address Line 3" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="city" className='mb-3' >
                  <Input placeholder="Town / City" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="postcode" className='mb-3' >
                  <Input placeholder="Postcode" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="country" className='mb-3' >
                  <Select
                    optionLabelProp="children"
                    placeholder="Select Country"
                  >
                    <Option value={1}>United Kingdom</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

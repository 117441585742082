import axios from "axios";

import { GET_ADDRESS_IO_API_KEY } from "../../constants/varibales";

const axiosInstance = axios.create({
  baseURL: "https://api.getAddress.io",
  headers: {
    "Content-Type": "application/json",
  },
  params: {
    'api-key': GET_ADDRESS_IO_API_KEY,
  },
});

const PostcodeApi = {
  autocompleteAddress: async (value) => {
    return new Promise((resolve, reject)=> {
      axiosInstance
        .get(`/autocomplete/${value}`)
        .then((response) => {
          resolve(response.data.suggestions || []);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  getAddressById: async (id) => {
    return new Promise((resolve, reject)=> {
      axiosInstance
        .get(`/get/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
};

export default PostcodeApi;
import { Button, Card, Col, List, Popconfirm, Row, Space, Table, Typography, message } from 'antd';
import React, { useMemo, useState } from 'react';

import styles from "../../Orders.module.scss";

import RevisedOfferApi from '../../../../apis/order-management/recycle-order/RevisedOfferApi';
import ListItem from '../ListItem';
import RevisedOffers from '../RevisedOffer';
import CreateRevisedOffer from '../RevisedOffer/CreateRevisedOffer';
import { CheckOutlined, CloseOutlined, DashboardOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { SiteOptions } from '../../../../components/layout/util/common';

const { Text, Paragraph } = Typography;

const columns = [
    {
        title: 'Model',
        dataIndex: 'model',
    },
    {
        title: 'Price',
        dataIndex: 'unit_price',
        render: (unit_price) => "£" + unit_price
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
    },
    {
        title: 'Total',
        dataIndex: 'total',
    },
];

export default function OrderItems({ activeOrder, total, setKey, baseUrl, setActiveOrderKey, isShort }) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [openCreateRevisedOffer, setOpenCreateRevisedOffer] = useState(false);

    const [revisedOfferKey, setRevisedOfferKey] = useState(0);
    const sendingShipment = useMemo(() => activeOrder?.order_shipments?.price || 0, [activeOrder]);
    const subtotal = useMemo(() => parseFloat(total) + parseFloat(sendingShipment), [total, sendingShipment]);
    const ship_medthod = useMemo(() => activeOrder.order_shipments || "-", [activeOrder]);
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    }

    const openCreateRevisedOfferDrawer = () => {
        setOpenCreateRevisedOffer(true);
    }

    const [loadingObj, setLoadingObj] = useState({
        sendOfferReminder: false,
    });

    const sendOfferReminderHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            sendOfferReminder: true,
        });

        try {
            if (!activeOrder.id)
                throw new Error('Order id is required!');

            const response = await RevisedOfferApi.sendOfferReminder(baseUrl, activeOrder.id);
            message.success(response.success);
            setLoadingObj({
                ...loadingObj,
                sendOfferReminder: false
            });
            setRevisedOfferKey(prevState => prevState + 1);
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                sendOfferReminder: false
            });
        }
    }

    const transformItems = (sourceItems, prefix = null, strikeThrough = false) => {
        return sourceItems?.map(item => ({
            key: prefix ? `${prefix}-${item.id}` : item.id,
            model: item.product?.model || item.products?.model,
            imei_no: item.imei_no ?? null,
            is_account_remove: item.is_account_remove,
            condition: item.condition?.name,
            network: item.network?.name || '-',
            unit_price: item.unit_price || item?.amount,
            qty: item.quantity || item?.qty,
            total: `£${((item.unit_price || item?.amount) * (item.quantity || item?.qty)).toFixed(2)}`,
            ...(strikeThrough && { strikeThrough: true })
        }));
    };

    const orderItems = useMemo(() => {
        let items = [];

        if (activeOrder?.filtered_revised_items && activeOrder.filtered_revised_items.length > 0)
            items.push(...transformItems(activeOrder.filtered_revised_items, 'revised', true));

        if (activeOrder?.revised_offer && activeOrder.revised_offer?.items && activeOrder.revised_offer.items.length > 0)
            items.push(...transformItems(activeOrder.revised_offer.items, 'revised-offer'));

        if (activeOrder?.filtered_non_revised_items && activeOrder.filtered_non_revised_items.length > 0)
            items.push(...transformItems(activeOrder.filtered_non_revised_items));

        if (items.length === 0 && activeOrder?.order_items && activeOrder.order_items.length > 0)
            items = transformItems(activeOrder.order_items);

        return items;
    }, [[activeOrder.filtered_revised_items, activeOrder.revised_offer?.items, activeOrder.filtered_non_revised_items, activeOrder.order_items]]);

    const newNetTotal = useMemo(() => {
        let totalOfNonRevisedAmount = activeOrder?.filtered_non_revised_items?.reduce((acc, item) => acc + (item.unit_price * +item.quantity), 0) || 0;
        let totalOfRevisedAmount = activeOrder?.revised_offer?.items?.reduce((acc, item) => acc + (item.unit_price * +item.quantity), 0) || 0;
        return totalOfNonRevisedAmount + totalOfRevisedAmount;
    }, [activeOrder.filtered_non_revised_items, activeOrder.revised_offer?.items]);

    const expandedRowRender = (record) => {
        const expandedColumns = [
            {
                title: 'IMEI No',
                key: 'imei_no',
                dataIndex: "imei_no",
                render: (_, { text }) => {
                    return text?.imei_no || "-";
                },
            },
            {
                title: 'Condition',
                dataIndex: 'condition',
                key: 'condition',
                render: (text) => (
                    <>
                        {text === 'Good' ? (
                            <>
                                <DashboardOutlined style={{ color: 'green', marginRight: 8 }} />
                                {text}
                            </>
                        ) : (
                            text
                        )}
                    </>
                ),
            },
            {
                title: 'Network',
                dataIndex: 'network',
                key: 'network',
                render: (text) => (
                    <>
                        {text === 'Unlocked' ? (
                            <>
                                <UnlockOutlined style={{ color: 'green', marginRight: 8 }} />
                                {text}
                            </>
                        ) : (
                            <>
                                <LockOutlined style={{ color: 'green', marginRight: 8 }} />
                                {text}
                            </>
                        )}
                    </>
                ),
            },
            {
                title: 'iCloud / Google account / Passcode are removed',
                dataIndex: 'is_account_remove',
                key: 'is_account_remove',
                render: (text) => (
                    text === 1 ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />
                ),
            },
        ];

        return (
            <Table
                columns={expandedColumns}
                dataSource={[record]}
                pagination={false}
                size="small"
                rowKey="key"
            />
        );
    };
    let PostageOptions = [
        { key: 'door-collection', label: 'Next-Day Door Collection' },
        { key: 'royal-mail', label: 'Send me a FREE Label' },
        { key: 'own-courier', label: 'I will use my courier' }
    ];
    return (
        <>
            <Card title={!isShort && (<Text>Order Details</Text>)}>
                <Row gutter={16} className={styles.model_card_row}>
                    <Col span={18}>
                        <>
                            {orderItems && orderItems.length > 0 &&
                                <Table
                                    size="small"
                                    rowSelection={
                                        !isShort
                                        && {
                                            selectedRowKeys,
                                            onChange: onSelectChange,
                                        }
                                    }
                                    columns={columns}
                                    dataSource={orderItems}
                                    pagination={false}
                                    rowClassName={(record) => (record.strikeThrough ? 'strike-through-row' : '')}
                                    expandable={
                                        !isShort
                                            ? {
                                                expandedRowRender: (record) =>
                                                    record.condition !== null
                                                        ? expandedRowRender(record)
                                                        : null,
                                                rowExpandable: (record) => Boolean(record.condition),
                                                defaultExpandedRowKeys: orderItems.length > 0 ? [orderItems[0].key] : [],
                                            }
                                            : undefined
                                    }
                                    rowKey={(record) => record.key}
                                />
                            }

                            {selectedRowKeys.length > 0 && activeOrder?.type !== "recycle" &&
                                <Button
                                    danger
                                    size='small'
                                    className='mt-5 ml-auto d-flex'
                                    onClick={openCreateRevisedOfferDrawer}
                                >
                                    Create Revised Offer
                                </Button>
                            }
                        </>
                    </Col>
                    <Col span={6}>
                        <List
                            size="small"
                            header={<Text>Order Summery</Text>}
                            bordered
                            dataSource={[
                                <ListItem key={5} keyText="Total: " valueText={'£' + subtotal.toFixed(2)} strikethrough={activeOrder?.filtered_revised_items && activeOrder?.filtered_revised_items.length > 0} />,
                                ((activeOrder?.filtered_revised_items && activeOrder?.filtered_revised_items?.length > 0) &&
                                    newNetTotal > 0 ?
                                    <ListItem key={6} keyText="Total: " valueText={'£' + newNetTotal.toFixed(2)} /> :
                                    <></>),
                            ]}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    </Col>
                    {!isShort &&
                        <>
                            <Col span={24}>
                                {ship_medthod?.ship_method &&
                                    <Paragraph>
                                        Postage Options: <Text type='secondary'>{PostageOptions.find(option => option.key === ship_medthod?.ship_method)?.label}</Text>
                                    </Paragraph>
                                }

                                {ship_medthod?.collection_date &&
                                    <Paragraph>
                                        Collection Date: <Text mark>{ship_medthod?.collection_date}</Text>
                                    </Paragraph>
                                }
                            </Col>

                            <Col
                                span={12}
                                className='mt-4'
                            >
                                <Text color='red'>Order Note: </Text>
                                <Text mark={!!activeOrder?.user?.order_note}>{activeOrder?.user?.order_note ?? "-"}</Text>
                            </Col>

                            {activeOrder?.has_revised_offers &&
                                <Col
                                    span={24}
                                    className='mt-4'
                                >
                                    <Space direction='horizontal justify-between w-100 mb-3' align='center'>
                                        <Text color='red'>Revised Offer: </Text>
                                        <Popconfirm
                                            title="Are you sure to send the reminder of revised offer?"
                                            okText="Yes"
                                            cancelText="No"
                                            onConfirm={sendOfferReminderHandler}
                                            placement='leftTop'
                                        >
                                            <Button type='primary' ghost>Remind Offer</Button>
                                        </Popconfirm>
                                    </Space>
                                    {baseUrl !== SiteOptions[4].value && (
                                        <RevisedOffers
                                            baseUrl={baseUrl}
                                            activeOrderId={activeOrder.id}
                                            revisedOfferKey={revisedOfferKey}
                                            setRevisedOfferKey={setRevisedOfferKey}
                                            setActiveOrderKey={setActiveOrderKey}
                                        />
                                    )}
                                </Col>
                            }
                        </>
                    }
                </Row>
            </Card>

            {(activeOrder?.type !== "recycle" && baseUrl !== SiteOptions[4].value) &&
                <CreateRevisedOffer
                    openCreateRevisedOffer={openCreateRevisedOffer}
                    setOpenCreateRevisedOffer={setOpenCreateRevisedOffer}
                    selectedRowKeys={selectedRowKeys}
                    activeOrder={activeOrder}
                    baseUrl={baseUrl}
                    setRevisedOfferKey={setRevisedOfferKey}
                    setKey={setKey}
                    setActiveOrderKey={setActiveOrderKey}
                />
            }
        </>
    )
}

import { LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import {
  Typography,
  Card,
  Col,
  Row,
  Spin,
  Table,
  Button,
  Select,
  message,
} from "antd";
import qs from 'qs';
import React, { useState, useRef, useEffect, useCallback } from "react";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.locale('en-gb');

import styles from './Logs.module.scss';

import { useSelector } from 'react-redux';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import QuickEdit from "./Components/QuickEdit";
import SelectedOptions from "./Components/SelectedOptions";
import useLogin from '../../hooks/useLogin';
import { SiteOptions } from '../../components/layout/util/common';
import LogsApi from '../../apis/order-management/LogsApi';

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)
dayjs.tz.setDefault('Europe/London');

const LoadingIcon = <LoadingOutlined style={{ fontSize: '2rem' }} spin />;
const { Text } = Typography;

export default function Logs(props) {
  const user = useSelector((state) => state.auth.user[0])

  useDocumentTitle(props.title);
  const { roles } = useSelector((state) => state.auth.user[0])
  const baseUrl = useSelector((state) => state.auth.base_url)

  const [tableTab, setTableTab] = useState(0);
  const [Logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(0);
  const [filterLogs, setFilterLogs] = useState(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 100,
    },
    query: "",
    status: null,
    wfc_status: null,
    userId: user?.id,
    is_paid: null
  });

  const queryRef = useRef('');

  const [selectedValues, setSelectedValues] = useState({
    selectedRowKeys: [],
    batchRecord: []
  });

  const [visible, setVisible] = useState(false);
  const [activeLogs, setActiveLogs] = useState(null);

  const rowSelection = {
    selectedRowKeys: selectedValues.selectedRowKeys,
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, selectedRows) => setSelectedValues({ selectedRowKeys, batchRecord: selectedRows }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE
    ]
  }


  const columns = [
    {
      title: "Log Name",
      dataIndex: "log_name",
      key: "log_name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Subject Type",
      key: "subject_type",
      dataIndex: "subject_type",
    },
    {
      title: "User Name",
      key: "user",
      dataIndex: "user",
      render: (user) => {
        return user?.name || "No Name Available";
      }
    },
    {
      title: "Created At",
      key: "created_at",
      dataIndex: "created_at",
      render: text => (text ? <Text type="secondary" title={dayjs(text).format('MMMM Do YYYY, h:mm:ss a')}>{dayjs(text).format("DD-MM-YYYY")}</Text> : "-"),
    },
    {
      title: "Updated At",
      key: "updated_at",
      dataIndex: "updated_at",
      render: text => (text ? <Text type="secondary" title={dayjs(text).format('MMMM Do YYYY, h:mm:ss a')}>{dayjs(text).format("DD-MM-YYYY")}</Text> : "-"),
    },
  ];

  const getQueryParams = (params) => ({
    current_page: params.pagination?.current,
    per_page: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });

  const fetchLogs = useCallback((isCancelled) => {
    setLoading(true);
    LogsApi.list(baseUrl, qs.stringify(getQueryParams(tableParams)))
      .then(({ data: logsData }) => {

        if (!isCancelled) {
          setLogs(logsData.data);
          setTableParams((prevTableParams) => ({
            ...prevTableParams,
            query: getValidSearchText(tableParams.query),
            pagination: {
              ...prevTableParams.pagination,
              current: logsData.current_page,
              pageSize: logsData.per_page,
              total: logsData.total,
            },
          }));
          setLoading(false);
        }
      })
      .catch(err => {
        if (!isCancelled)
          setLoading(false);
      });
  }, [baseUrl, tableParams, getQueryParams]);

  function getValidSearchText(query, searchText) {
    const searchBoxText = queryRef.current?.input?.value;
    if (searchText === "") {
      if (searchBoxText === "") return "";
      return query;
    }
    return searchText;
  }

  useEffect(() => {
    let isCancelled = false
    fetchLogs(isCancelled);

    return () => {
      isCancelled = true
    }
  }, [key, JSON.stringify(tableParams)]);


  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setLogs([]);
    }
  };


  const showModal = (item) => {
    setVisible(true);
    setActiveLogs(item);
  };

  const handleReset = () => {
    setActiveLogs(null);
    setVisible(false);
    setKey(prevState => prevState + 1);
  }

  const handleCancel = () => {
    setVisible(false);
    setActiveLogs(null);
  };


  const rowsUnCheckHandler = () => {
    setSelectedValues({
      selectedRowKeys: [],
      batchRecord: []
    })
  }


  const { handleChangeLogin } = useLogin(setLoading);


  const pageLoading = () => {
    setTableTab(0);
    setKey(prevKey => prevKey + 1)
  };

  const handleBaseUrlChange = async (value) => {
    const result = await handleChangeLogin(value);
    if (result.success) {
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
      setKey(prevKey => prevKey + 1)
    }
    else {
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
    }
  };


  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <div className="site-page-header-ghost-wrapper">

              <Card
                bordered={false}
                className={`criclebox tablespace ${styles.table}`}


                styles={{
                  header: {
                    padding: 0,
                    border: 'none'
                  }
                }}
              >
                <Row className="mb-5" justify="space-between" align="top">
                  <Col md={24} className='d-inline-flex mb-4'>
                    <Button
                      icon={<SyncOutlined />}
                      loading={loading}
                      onClick={pageLoading}
                      className="mr-2 px-2"
                    />
                    <Select
                      value={baseUrl}
                      onChange={handleBaseUrlChange}
                      className="mr-4"
                      style={{ width: 260 }}
                      options={SiteOptions.map(option => ({
                        ...option,
                        disabled: option.id === 3,
                      }))}
                      disabled={loading}
                    />

                  </Col>

                </Row>
                <div className="table-responsive">

                  <SelectedOptions
                    selectedRowKeys={selectedValues.selectedRowKeys}
                    selectedRows={selectedValues.batchRecord}
                    setIndex={setKey}
                    rowsUnCheckHandler={rowsUnCheckHandler}
                  />

                  <Table
                    columns={columns}
                    rowKey={(record) => record.id}
                    rowSelection={rowSelection}
                    dataSource={filterLogs === null ? Logs : filterLogs}
                    loading={{ spinning: loading, indicator: <Spin size="middle" indicator={LoadingIcon} /> }}
                    pagination={{
                      showSizeChanger: true,
                      showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
                      showQuickJumper: true,
                      pageSizeOptions: [100, 200, 300, 400, 500],
                      defaultPageSize: tableParams.pagination.pageSize,
                      position: ["bottomRight"],
                      ...tableParams.pagination
                    }}
                    className="ant-border-space"
                    size="small"
                    ellipsis
                    responsive
                    onChange={handleTableChange}
                    onRow={(record) => {
                      return {
                        onClick: (_) => {
                          showModal(record)
                        }
                      };
                    }}
                  />
                </div>
              </Card>
            </div>
          </Col>
        </Row>

        {visible &&
          <QuickEdit
            visible={visible}
            handleCancel={handleCancel}
            activeLogs={activeLogs}
            handleReset={handleReset}
            roles={roles}
            setIndex={setKey}
          />
        }
      </div>
    </>
  );
}
import {
  HomeOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Row,
  Select,
  message
} from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";


import { useAuth } from "../../contexts/AuthContext";
import useLogin from '../../hooks/useLogin';
import { logout } from "../../store/slices/auth";
import { SiteOptions, dropdownConditionals } from "./util/common";

const profile = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#111827"
    ></path>
  </svg>,
];

const settingIcon = [
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M20.1 9.2214C18.29 9.2214 17.55 7.9414 18.45 6.3714C18.97 5.4614 18.66 4.3014 17.75 3.7814L16.02 2.7914C15.23 2.3214 14.21 2.6014 13.74 3.3914L13.63 3.5814C12.73 5.1514 11.25 5.1514 10.34 3.5814L10.23 3.3914C9.78 2.6014 8.76 2.3214 7.97 2.7914L6.24 3.7814C5.33 4.3014 5.02 5.4714 5.54 6.3814C6.45 7.9414 5.71 9.2214 3.9 9.2214C2.86 9.2214 2 10.0714 2 11.1214V12.8814C2 13.9214 2.85 14.7814 3.9 14.7814C5.71 14.7814 6.45 16.0614 5.54 17.6314C5.02 18.5414 5.33 19.7014 6.24 20.2214L7.97 21.2114C8.76 21.6814 9.78 21.4014 10.25 20.6114L10.36 20.4214C11.26 18.8514 12.74 18.8514 13.65 20.4214L13.76 20.6114C14.23 21.4014 15.25 21.6814 16.04 21.2114L17.77 20.2214C18.68 19.7014 18.99 18.5314 18.47 17.6314C17.56 16.0614 18.3 14.7814 20.11 14.7814C21.15 14.7814 22.01 13.9314 22.01 12.8814V11.1214C22 10.0814 21.15 9.2214 20.1 9.2214ZM12 15.2514C10.21 15.2514 8.75 13.7914 8.75 12.0014C8.75 10.2114 10.21 8.7514 12 8.7514C13.79 8.7514 15.25 10.2114 15.25 12.0014C15.25 13.7914 13.79 15.2514 12 15.2514Z"
      fill="#292D32"
    />
  </svg>,
];

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header({
  name,
  subName,
  onPress,
  setLoading
}) {
  const history = useHistory();
  let { pathname } = useLocation();

  const { logout: firebaseLogout } = useAuth()
  const dispatch = useDispatch();
  const baseUrl = useSelector((state) => state.auth.base_url)

  const [messageApi, contextHolder] = message.useMessage();

  const menu = {
    items: [
      {
        key: '1',
        label: (
          <Link to="/profile" className="btn-sign-in">
            Profile
          </Link>
        ),
      },
      {
        key: '2',
        label: (
          <a onClick={() => { handleLogout(true); }}>
            Sign Out
          </a>
        ),
      },
    ]
  }

  const setting = {
    items: [
      {
        key: '1',
        label: (
          <Link to="/settings/product-category" className="btn-sign-in">
            Product Category
          </Link>
        ),
      },
      {
        key: '2',
        label: (
          <Link to="/settings/product-series" className="btn-sign-in">
            Product Series
          </Link>
        ),
      },
      {
        key: '3',
        label: (
          <Link to="/settings/services" className="btn-sign-in">
            Services
          </Link>
        ),
      },
      {
        key: '4',
        label: (
          <Link to="/settings/products" className="btn-sign-in">
            Products
          </Link>
        ),
      },
      {
        type: 'divider',
      },
      {
        key: '5',
        label: (
          <Link to="/settings/device-condition" className="btn-sign-in">
            Condition
          </Link>
        ),
      },
      {
        key: '6',
        label: (
          <Link to="/settings/condition-description" className="btn-sign-in">
            Condition Description
          </Link>
        ),
      },
    ]
  }

  const { handleChangeLogin } = useLogin(setLoading);

  // useEffect(() => window.scrollTo(0, 0));

  async function handleLogout(isFirebaseLogout = false) {
    try {
      await messageApi
        .open({
          type: 'loading',
          content: 'Logout in progress..',
          duration: 2.5,
        });
      if (isFirebaseLogout) {
        await firebaseLogout();
      }
      await dispatch(logout()).unwrap();
      history.push("/sign-in")
      message.success({
        content: 'Logout Successfully',
        transitionName: 'move-down',
      });
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }
    catch (err) {
      message.error({
        content: 'Logout Failed',
        transitionName: 'move-down',
      });
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      history.push("/sign-in")
    }
  }

  const handleChange = async (value) => {
    const result = await handleChangeLogin(value);
    if (result.success) {
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
    }
    else {
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Row gutter={[24, 0]}>
        <Col span={24} md={12} className="header-control-alt">
          <Breadcrumb style={{ alignItems: 'center' }}
            items={[
              {
                title: <HomeOutlined />,
                href: "/",
              },
              { title: name },
            ]}
          />
          {dropdownConditionals.some(page => pathname === page) &&
            <Select
              size="middle"
              defaultValue={baseUrl}
              onChange={handleChange}
              style={{ width: 260, marginLeft: '1rem' }}
              options={SiteOptions}
            />
          }
        </Col>
        <Col span={24} md={12} className="header-control">
          <Button
            type="link"
            className="sidebar-togglerr"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>

          <Dropdown menu={menu} placement="bottomRight">
            <Button type="default">{profile}</Button>
          </Dropdown>
          <Dropdown menu={setting} placement="bottomRight" className="mx-2">
            <Button type="default">{settingIcon}</Button>
          </Dropdown>

        </Col>
      </Row>
    </>
  );
}

export default Header;

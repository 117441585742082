import React from 'react'
import PropTypes from 'prop-types';

import styles from './Icon.module.scss'

export default function IconBase({ component, className = '', isWrapper = true, iconColor, ...rest }) {
  const IconElement = component;

  if (!isWrapper) {
    return <IconElement {...rest} className={[styles.iconBase, className].join(' ')} />;
  }

  return (
    <IconElement
      {...rest}
      style={{ fill: iconColor }}
      className={[styles.iconBase, className].join(' ')}
    />
  )
}

IconBase.propTypes = {
  component: PropTypes.elementType.isRequired,
  className: PropTypes.string,
};


import React, { useState } from 'react';
import { Drawer, List } from 'antd';
import { FieldTimeOutlined, MailOutlined, HddOutlined } from '@ant-design/icons';
import { MessageOutlined } from '@ant-design/icons';

import styles from '../../Orders.module.scss';

import GenerateTicket from './GenerateTicket';
import WFCNoteUpdate from '../WFCNoteUpdate';
import OrderEvidence from '../../../../components/OrderEvidence';
import SendMessage from '../../../../components/SendMessage';

export default function DrawerWrapper({ activeOrder, baseUrl, handleReset, employees, notificationTemplate }) {
    const dataSourceList = [
        {
            id: 1,
            name: 'Order Evidence',
            alias: 'orderEvidence',
            icon: (<HddOutlined className={styles.iconAlt} />),
            width: 1000
        },
        {
            id: 2,
            name: 'WFC Note',
            alias: 'wfcNote',
            icon: (<FieldTimeOutlined className={styles.iconAlt} />),
            width: 900
        },
        {
            id: 3,
            name: 'Send Ticket',
            alias: 'sendTicket',
            icon: (<MailOutlined className={styles.iconAlt} />),
            width: 900
        },
        {
            id: 4,
            name: 'Send SMS',
            alias: 'sendSMS',
            icon: (<MessageOutlined className={styles.iconAlt} />),
            width: 900
        },
    ];

    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const showDrawer = (item) => {
        setSelectedItem(item);
        setOpen(true);
    };

    const onClose = () => {
        setSelectedItem(null);
        setOpen(false);
    };

    return (
        <>
            <List
                className='mb-4'
                grid={{ gutter: 16, column: activeOrder.return_request?.length > 0 ? 5 : 4 }}
                size="small"
                dataSource={dataSourceList}
                bordered
                renderItem={(item) => {
                    if (item.alias === 'returns' && !activeOrder.return_request?.length > 0) return;
                    return (
                        <List.Item
                            className='py-2 mb-0 cursor-pointer'
                            key={item.id}
                            onClick={() => showDrawer(item.alias)}
                        >
                            <List.Item.Meta
                                avatar={item.icon}
                                title={item.name}
                            />
                        </List.Item>
                    );
                }}
            />

            <Drawer
                width={dataSourceList.find(item => item.alias === selectedItem)?.width || 640}
                placement="left"
                onClose={onClose}
                open={open}
                title={dataSourceList.find(item => item.alias === selectedItem)?.name}
            >
                {selectedItem === 'orderEvidence' &&
                    <OrderEvidence
                        id={activeOrder?.id}
                        baseUrl={baseUrl}
                    />
                }

                {selectedItem === 'wfcNote' &&
                    <WFCNoteUpdate
                        key={activeOrder.id + '__EditModel'}
                        orderId={activeOrder.id}
                        employees={employees}
                        baseUrl={baseUrl}
                        handleReset={handleReset}
                    />
                }

                {selectedItem === 'sendTicket' &&
                    <GenerateTicket
                        activeOrder={activeOrder}
                        notificationTemplate={notificationTemplate}
                        baseUrl={baseUrl}
                        handleReset={handleReset}
                        employees={employees}
                    />
                }

                {selectedItem === 'sendSMS' &&
                    <SendMessage
                        activeOrder={activeOrder}
                        baseUrl={baseUrl}
                    />
                }
            </Drawer>
        </>
    );
}

import React, { useState, useMemo } from "react";
import { Popover, Spin, Typography, Flex, List, Col, Row } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import OrderItems from "./QuickEdit/OrderItems";
import { getPayStatusByValue } from "../util/common";
import StatusBadge from "../../../components/StatusBadge";
import DirectOrdersApi from "../../../apis/order-management/direct-order/DirectOrdersApi";
import ListItem from "./ListItem";

const { Text } = Typography;

export default function TableRowDetails({ id, children, baseUrl, width, ...rest }) {
  const [isLoading, setIsLoading] = useState(false);
  const [activeOrder, setActiveOrder] = useState({});
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [showCost, setShowCost] = useState(true);
  const [showPayment, setShowPayment] = useState(true);

  const toggleCost = () => {
    setShowCost(!showCost);
  };
  const togglePayment = () => {
    setShowPayment(!showPayment);
  };

  const fetchOrder = async () => {
    setIsLoading(true);
    try {
      const order = await DirectOrdersApi.getOne(baseUrl, id);
      setActiveOrder(order);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenChange = (visible) => {
    setIsPopoverOpen(visible);
    if (visible && !activeOrder.id) {
      fetchOrder();
    }
  };

  const statusObj = useMemo(() => getPayStatusByValue(activeOrder.is_paid), [activeOrder.is_paid]);

  const dataSourceOrderSummary = useMemo(() => [
    <ListItem
      key={0}
      keyText="Order # :"
      valueText={activeOrder.order_no}
      valueCopyable
    />,
    <ListItem
      key={1}
      keyText="Pay Status :"
      valueText={<StatusBadge value={statusObj?.value} color={statusObj?.color} label={statusObj?.label} />}
    />,
    <ListItem
      key={2}
      keyText="Repair Cost"
      valueText={activeOrder?.repair_cost}
      showCost={showCost}
      activeOrder={activeOrder}
      type="cost"
      onToggleDetails={toggleCost}
    />,
    <ListItem
      key={3}
      keyText="Deposit"
      activeOrder={activeOrder}
      type="payment"
      showPayment={showPayment}
      valueText={activeOrder?.deposit_amount}
      onToggleDetails={togglePayment}
    />,
    <ListItem
      key={4}
      keyText="Balance Due"
      bgcolor={parseInt(activeOrder?.due_amount) === 0 ? "red" : "green"}
      valueText={parseFloat(activeOrder?.due_amount).toFixed(2)}
    />
  ], [activeOrder, showCost, showPayment, statusObj]);


  return (
    <Popover
      content={
        <div style={{ width: width || 1000 }}>
          <Spin
            indicator={<LoadingOutlined spin />}
            size="large"
            spinning={isLoading}
          >
            <Row>
              {activeOrder &&
                <Col span={18}>
                  <OrderItems
                    activeOrder={activeOrder}
                    baseUrl={baseUrl}
                    isShort
                  />
                </Col>
              }

              {activeOrder.user &&
                <Col span={6} className="pl-2">
                  <List
                    size="small"
                    header={<Text>Order Summary</Text>}
                    bordered
                    dataSource={dataSourceOrderSummary}
                    renderItem={(item, idx) => (
                      <List.Item style={{ backgroundColor: idx === 4 ? (parseInt(activeOrder?.due_amount) === 0 ? "#8d8" : "#f29f9f") : 'transparent' }}>
                        {item}
                      </List.Item>
                    )}
                  />
                </Col>
              }
            </Row>
          </Spin>
        </div>
      }
      title={
        <Flex align="center">
          <StatusBadge
            value={statusObj?.status}
            color={statusObj?.status}
            label={statusObj?.label}
          />
          <Text strong>{"# " + (activeOrder?.order_no || id)}</Text>
        </Flex>
      }
      trigger="hover"
      placement="topLeft"
      open={isPopoverOpen}
      onOpenChange={handleOpenChange}
      {...rest}
    >
      <Text className="text-primary" underline>
        {children}
      </Text>
    </Popover>
  );
}
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Drawer, List, Row, Space, Table, Typography, message } from 'antd';

import styles from "../../Orders.module.scss";

import { SyncOutlined } from '@ant-design/icons';
import OrdersApi from '../../../../apis/order-management/repair-order/OrdersApi';
import PayTypeBadge from '../../../../components/PayTypeBadge';
import Loading from '../../../../components/loading';
import { convertStringToNumber, hasObjects } from '../../../../util/helpers';
import ListItem from '../ListItem';
import PaymentIdUpdateFrm from './PaymentIdUpdateFrm';

const { Text } = Typography;

const transformColumns = (isIsoFormat) => ([
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name) => <Text ellipsis>{name}</Text>
  },
  {
    title: 'Price',
    dataIndex: 'unit_price',
    render: (price) => "£" + (isIsoFormat ? (price / 100).toFixed(2) : price)
  },
  {
    title: 'Qty',
    dataIndex: 'quantity',
  },
  {
    title: 'Total',
    dataIndex: 'total_amount',
    render: (amount) => "£" + (isIsoFormat ? (amount / 100).toFixed(2) : amount)
  },
]);

export default function PaymentDetails({ openPaymentDetails, setOpenPaymentDetails, activeOrder, baseUrl, setActiveOrderKey }) {
  const [paymentDetails, setPaymentDetails] = useState({});
  const [loadingObj, setLoadingObj] = useState({
    paymentLayout: false,
    fullyCapture: false,
  });
  const [key, setKey] = useState(0);

  const onClose = () => {
    setOpenPaymentDetails(false);
  }

  useEffect(() => {
    setLoadingObj(prevLoadingObj => ({ ...prevLoadingObj, paymentLayout: true }));
    OrdersApi.paymentDetails(baseUrl, activeOrder?.payment_id, activeOrder?.payment_type)
      .then(response => {
        setPaymentDetails(response.data);
      })
      .catch(err => {
        //
      })
      .finally(() => setLoadingObj(prevLoadingObj => ({ ...prevLoadingObj, paymentLayout: false })));
  }, [key]);

  const getStatuses = useMemo(() => {
    const statuses = [
      {
        keyText: 'Status',
        valueText: paymentDetails?.status,
      }
    ];

    if (paymentDetails?.fraud_status) {
      statuses.push({
        keyText: 'Fraud Status',
        valueText: paymentDetails?.fraud_status,
      });
    }

    if (paymentDetails?.state) {
      statuses.push({
        keyText: 'State',
        valueText: paymentDetails?.state,
      });
    }

    return statuses;
  }, [paymentDetails]);

  const getAmounts = useMemo(() => {
    const amounts = [
      {
        keyText: 'Amount',
        valueText: '£' + (paymentDetails?.amount || paymentDetails?.order_amount),
      }
    ];

    if (paymentDetails?.amount_received) {
      amounts.push({
        keyText: 'Authorized',
        valueText: '£' + paymentDetails?.amount_received,
      });
    }

    if (paymentDetails?.original_order_amount) {
      amounts.push({
        keyText: 'Original Order',
        valueText: '£' + paymentDetails?.original_order_amount,
      });
    }

    if (paymentDetails?.amount_capturable) {
      amounts.push({
        keyText: 'Capturable',
        valueText: '£' + paymentDetails?.amount_capturable,
      });
    }

    if (paymentDetails?.captured_amount) {
      amounts.push({
        keyText: 'Captured',
        valueText: '£' + paymentDetails?.captured_amount,
      });
    }

    if (paymentDetails?.refunded_amount) {
      amounts.push({
        keyText: 'Refunded',
        valueText: '£' + paymentDetails?.refunded_amount,
      });
    }

    if (paymentDetails?.remaining_authorized_amount) {
      amounts.push({
        keyText: 'Remaining Authorized Amount',
        valueText: '£' + paymentDetails?.remaining_authorized_amount,
      });
    }

    return amounts;
  }, [paymentDetails]);

  const addressText = (address) => {
    if (!address) return [];

    return [
      address?.line_1 || address?.line1 || address?.street_address || address?.address_line_1 || 'N/A',
      address?.line_2 || address?.line2 || address?.street_address_2 || address?.address_line_2 || 'N/A',
      address?.city || address?.admin_area_2 || 'N/A',
      address?.state || address?.admin_area_1 || 'N/A',
      address?.postal_code || 'N/A',
      address?.country || address?.country_code || 'N/A',
    ]
  }

  const fullyCapture = async () => {
    setLoadingObj(prevLoadingObj => ({ ...prevLoadingObj, fullyCapture: true }));
    try {
      const response = await OrdersApi.paymentCaptureInKlarna(baseUrl, activeOrder?.payment_id, convertStringToNumber(paymentDetails?.remaining_authorized_amount));
      if (response) {
        setKey(prevKey => prevKey + 1);
        message.success(response.data);
      }
      setLoadingObj(prevLoadingObj => ({ ...prevLoadingObj, fullyCapture: false }));
    } catch (error) {
      message.error(error.message);
      setLoadingObj(prevLoadingObj => ({ ...prevLoadingObj, fullyCapture: false }));
    }
  }

  return (
    <Drawer
      title="Payment Status"
      extra={
        <Space size="middle" align='center'>
          {activeOrder?.payment_type === 2 && activeOrder.is_paid === 1 &&
            paymentDetails?.remaining_authorized_amount !== '0.00' ?
            <Button danger onClick={fullyCapture} loading={loadingObj.fullyCapture}>Fully Capture</Button> : null}
          {hasObjects(paymentDetails) &&
            <Button
              icon={<SyncOutlined />}
              onClick={() => {
                setKey(prevKey => prevKey + 1)
              }}
              loading={loadingObj.paymentLayout}
            />
          }
        </Space>
      }
      placement="left"
      closable={true}
      onClose={onClose}
      open={openPaymentDetails}
      size='large'
    >
      {loadingObj.paymentLayout ? <Loading /> :
        hasObjects(paymentDetails) ?
          <Row gutter={16} className={styles.model_card_row}>
            <Col
              span={12}
              className='mb-3'
            >
              <List
                size="small"
                header={<Text>Summery</Text>}
                bordered
                dataSource={[
                  <ListItem key={3}
                    keyText="Payment # : "
                    valueText={paymentDetails?.payment_id || paymentDetails.order_id} valueCopyable />,
                  <ListItem key={4}
                    keyText="Payment method : "
                    valueText={<div className="d-flex">
                      {/* <StatusBadge value={statusObj.value} color={statusObj.color} label={statusObj.label} /> */}
                      <PayTypeBadge type={activeOrder?.payment_type} />
                    </div>}
                  />,
                  ...getStatuses.map((item, idx) => (
                    <ListItem
                      key={idx}
                      keyText={item.keyText}
                      valueText={item.valueText}
                    />
                  ))
                ]}
                renderItem={item => <List.Item>{item}</List.Item>}
              />
            </Col>

            <Col
              span={12}
              className='mb-3'
            >
              <List
                size="small"
                header={<Text>Order Amount</Text>}
                bordered
                dataSource={getAmounts.map((item, idx) => (
                  <ListItem
                    key={idx}
                    keyText={item.keyText}
                    valueText={item.valueText}
                  />
                ))}
                renderItem={item => <List.Item>{item}</List.Item>}
              />
            </Col>

            <Col
              span={12}
              className='mb-3'
            >
              <List
                size="small"
                header={<Text>Contact</Text>}
                bordered
                dataSource={[
                  <ListItem key={0}
                    keyText="Name : "
                    valueText={paymentDetails?.customer_name || ''}
                    valueCopyable
                  />,
                  <ListItem key={1}
                    keyText="E-Mail : "
                    valueText={paymentDetails?.customer_email || ''}
                    valueCopyable
                  />,
                  <ListItem key={2}
                    keyText="Phone : "
                    valueText={paymentDetails?.customer_phone || ''}
                    valueCopyable
                  />,
                ]}
                renderItem={item => <List.Item>{item}</List.Item>}
              />
            </Col>

            {hasObjects(paymentDetails?.shipping_address) &&
              <Col
                span={12}
                className='mb-3'
              >
                <List
                  size="small"
                  header={<Text>Shipping Address</Text>}
                  bordered
                  dataSource={addressText(paymentDetails.shipping_address)}
                  renderItem={item => (
                    <List.Item>
                      <ListItem
                        key={0}
                        valueText={item}
                        valueCopyable={(typeof item === 'string') && (paymentDetails?.billing_address?.postal_code && paymentDetails?.billing_address?.postal_code.replace(/\s/g, "") == item.replace(/\s/g, ""))}
                      />
                    </List.Item>
                  )}
                />
              </Col>
            }

            {hasObjects(paymentDetails?.billing_address) &&
              <Col
                span={12}
                className='mb-3'
              >
                <List
                  size="small"
                  header={<Text>Billing Address</Text>}
                  bordered
                  dataSource={addressText(paymentDetails.billing_address)}
                  renderItem={item => (
                    <List.Item>
                      <ListItem
                        key={0}
                        valueText={item}
                        valueCopyable={(typeof item === 'string') && (paymentDetails?.billing_address?.postal_code && paymentDetails?.billing_address?.postal_code.replace(/\s/g, "") == item.replace(/\s/g, ""))}
                      />
                    </List.Item>
                  )}
                />
              </Col>
            }

            {paymentDetails?.order_lines && paymentDetails?.order_lines?.length > 0 &&
              <Col
                span={24}
                className='my-3'
              >
                <Card title="Order Lines">
                  <Table
                    size='small'
                    columns={transformColumns(([2, 3].includes(activeOrder?.payment_type) ? true : false))}
                    dataSource={paymentDetails?.order_lines?.map((item, idx) => ({
                      ...item,
                      key: idx,
                      unit_price: item.unit_price || item.price || item.unit_amount?.value,
                      total_amount: item.total_amount || ((item.price || item.unit_amount?.value) * +item.quantity)
                    })) || []}
                    pagination={false}
                    rowKey={record => record.id || record.reference}
                  />
                </Card>
              </Col>
            }
          </Row>
          :
          <>
            <Text strong className='text-center py-3'>No Payment Details Found</Text>

            {activeOrder.is_paid === 1 &&
              activeOrder?.payment_type === 2 &&
              <PaymentIdUpdateFrm
                paymentId={activeOrder?.payment_id}
                activeOrderId={activeOrder?.id}
                baseUrl={baseUrl}
                setPaymentDetails={setKey}
              />
            }
          </>
      }
    </Drawer>
  )
}

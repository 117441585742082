import React from 'react';
import { RedoOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Progress, Row, message } from 'antd';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import './styles/imagePreview.css';

import EvidenceApi from '../../apis/order-management/EvidenceApi';
import { getValidOrderType } from '../../helpers/getBaseUrlByOrderId';

export const ImagePreview = ({ captureObj, setCaptureObj, isFullscreen, baseUrl, id }) => {
    const { pathname } = useLocation();
    let classNameFullscreen = isFullscreen ? 'demo-image-preview-fullscreen' : '';

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    };

    return (
        <>
            <div className={'demo-image-preview ' + classNameFullscreen}>
                <img src={captureObj.imageObj} />
            </div>

            <Row
                align="middle"
                justify="center"
                className="mt-4"
            >
                <Col>
                    <Button
                        className="mr-2"
                        size='large'
                        type="dashed"
                        icon={<RedoOutlined />}
                        onClick={() => setCaptureObj(prevCaptureObj => ({ ...prevCaptureObj, imageObj: null }))}
                    />
                </Col>
                <Col>
                    <Button
                        size='large'
                        type="primary"
                        icon={<UploadOutlined />}
                        loading={captureObj.isLoading}
                        onClick={async () => {
                            try {
                                setCaptureObj(prevCaptureObj => ({
                                    ...prevCaptureObj,
                                    isLoading: true,
                                }));

                                const blob = dataURItoBlob(captureObj.imageObj);
                                const fileType = blob.type.split('/')[1];

                                // const formData = new FormData();
                                // formData.append('file', blob);

                                // EvidenceApi.uploadOne(baseUrl, id, formData, (progressEvent) => {
                                //     setCaptureObj(prevCaptureObj => ({
                                //         ...prevCaptureObj,
                                //         uploadProgress: 0,
                                //     }));
                                //     const { loaded, total } = progressEvent;
                                //     const percentage = Math.floor((loaded * 100) / total);
                                //     setCaptureObj(prevCaptureObj => ({
                                //         ...prevCaptureObj,
                                //         uploadProgress: percentage,
                                //     }));
                                // })
                                //     .then(response => {
                                //         if (response.success) {
                                //             message.success(`Files uploaded successfully.`);
                                //         } else {
                                //             message.error(`Files upload failed.`);
                                //         }

                                //         setCaptureObj(prevCaptureObj => ({
                                //             ...prevCaptureObj,
                                //             isLoading: false,
                                //         }));
                                //     })
                                //     .catch(error => {
                                //         message.error(`file upload failed.`);
                                //         setCaptureObj(prevCaptureObj => ({
                                //             ...prevCaptureObj,
                                //             isLoading: false,
                                //         }));
                                //     });

                                let orderType = getValidOrderType(pathname.split('/')[1]);
                                if (!orderType) {
                                    return;
                                }

                                const { data: responseData, success: successOfPreSignUrl } = await EvidenceApi.generatePresignedUrl(baseUrl, orderType, id, fileType);
                                if (successOfPreSignUrl) {
                                    const { url } = responseData;
                                    const response = await EvidenceApi.uploadToS3(url, blob, (progressEvent) => {
                                        setCaptureObj(prevCaptureObj => ({
                                            ...prevCaptureObj,
                                            uploadProgress: 0,
                                        }));
                                        const { loaded, total } = progressEvent;
                                        const percentage = Math.floor((loaded * 100) / total);
                                        setCaptureObj(prevCaptureObj => ({
                                            ...prevCaptureObj,
                                            uploadProgress: percentage,
                                        }));
                                    }
                                    );
                                    console.log('Upload to S3:', response);
                                    if (response) {
                                        message.success(`File uploaded successfully.`);
                                    } else {
                                        message.error(`File upload failed.`);
                                    }
                                }
                                else {
                                    message.error('Temporary upload url failed.');
                                }
                                setCaptureObj(prevCaptureObj => ({
                                    ...prevCaptureObj,
                                    isLoading: false,
                                }));

                            } catch (error) {
                                message.error('Temporary upload url failed, Something went wrong.');
                                setCaptureObj(prevCaptureObj => ({
                                    ...prevCaptureObj,
                                    isLoading: false,
                                }));
                            }
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24} className="justify-center align-center">
                    {captureObj.uploadProgress > 0 && <div className='pt-4 px-6 mx-auto'><Progress percent={captureObj.uploadProgress} /></div>}
                </Col>
            </Row>
        </>
    );
};

ImagePreview.propTypes = {
    dataUri: PropTypes.string,
    isFullscreen: PropTypes.bool
};

export default ImagePreview;
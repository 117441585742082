import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Drawer, Affix } from "antd";

import Sidenav from "./Sidenav";
import SidenavSmall from "./SidenavSmall";
import Header from "./Header";
import Footer from "./Footer";
import Loading from "../../components/loading/loadingBig";
import useSideMenuBadgeCount from "../../hooks/useSideMenuBadgeCount";

const { Header: AntHeader, Content, Sider } = Layout;

const ignoredPaths = ['sign-in', 'chat', 'orders','direct-orders', 'orders/urgent', 'orders/collection', 'recycle-orders', 'recycle-orders/collection'];

function Main(props) {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#1890ff");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const [loading, setLoading] = useState(false);

  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  const { counts } = useSideMenuBadgeCount();

  const { children } = props;

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  useEffect(() => {
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [pathname]);

  return (
      <Layout
        className={`layout-dashboard ${pathname === "profile" ? "layout-profile" : ""
          } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
      >
        <Drawer
          title={false}
          placement={placement === "right" ? "left" : "right"}
          closable={false}
          onClose={() => setVisible(false)}
          open={visible}
          key={placement === "right" ? "left" : "right"}
          width={250}
          className={`drawer-sidebar ${pathname === "rtl" ? "drawer-sidebar-rtl" : ""
            } `}
        >
          <Layout
            className={`layout-dashboard ${pathname === "rtl" ? "layout-dashboard-rtl" : ""
              }`}
          >
            <Sider
              trigger={null}
              width={250}
              theme="light"
              className={`sider-primary ant-layout-sider-primary ${sidenavType === "#fff" ? "active-route" : ""
                }`}
              style={{ background: sidenavType }}
            >
              <Sidenav color={sidenavColor} setVisible={setVisible} counts={counts} />
            </Sider>
          </Layout>
        </Drawer>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed, type) => {
            //
          }}
          trigger={null}
          width={80}
          theme="light"
          className={`sider-primary ant-layout-sider-primary ${sidenavType === "#fff" ? "active-route" : ""
            }`}
          style={{ background: sidenavType }}
        >
          <SidenavSmall color={sidenavColor} counts={counts} />
        </Sider>
        <Layout>
          {!ignoredPaths.includes(pathname) &&
            <>
              {fixed ? (
                <Affix>
                  <AntHeader className={`${fixed ? "ant-header-fixed mx-0 my-1" : ""}`}>
                    <Header
                      onPress={openDrawer}
                      name={pathname}
                      subName={pathname}
                      handleSidenavColor={handleSidenavColor}
                      handleSidenavType={handleSidenavType}
                      handleFixedNavbar={handleFixedNavbar}
                      setLoading={setLoading}
                    />
                  </AntHeader>
                </Affix>
              ) : (
                <AntHeader className={`${fixed ? "ant-header-fixed mx-0 my-1" : ""}`}>
                  <Header
                    onPress={openDrawer}
                    name={pathname}
                    subName={pathname}
                    handleSidenavColor={handleSidenavColor}
                    handleSidenavType={handleSidenavType}
                    handleFixedNavbar={handleFixedNavbar}
                    setLoading={setLoading}
                  />
                </AntHeader>
              )}
            </>
          }
          {!loading ? <Content className="content-ant mt-4">{children}</Content> : <Loading />}
          <Footer />
        </Layout>
      </Layout>
  );
}

export default Main;

import { Typography, message } from 'antd';
import React, { useState } from 'react';

import styles from '../Orders.module.scss';

const { Paragraph } = Typography;

export default function ListItem({ keyText, keyCopyable, valueText, valueCopyable, strikethrough, onUpdate, fieldType, editable, isMark }) {
    const classNames = [styles.paragraph];
    if (strikethrough) {
        classNames.push(styles.strikethrough);
    }
    const validateValue = (value) => {
        switch (fieldType) {
            case 'email':
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    return 'Invalid email format.';
                }
                break;
            case 'phone_number':
                if (!/^\d{10,12}$/.test(value)) {
                    return 'Invalid phone number format.';
                }
                break;
            default:
                return null;
        }
    };

    const handleSaveClick = (value) => {
        const validationError = validateValue(value);
        if (validationError) {
            message.error(validationError);
            return;
        }

        if (onUpdate) {
            onUpdate(fieldType, value);
        }
    };
    return (
        <div className={styles.ListItemWrap}>
            {keyText && <Paragraph className={styles.paragraph} copyable={keyCopyable}>{keyText}</Paragraph>}
            <div className={`d-flex ${styles.valueContainer}`}>
                <Paragraph
                    className={styles.paragraph}
                    editable={editable ? {
                        onChange: (value) => handleSaveClick(value),
                    } : false}
                    copyable={valueCopyable}
                    mark={isMark}
                >
                    {valueText}
                </Paragraph>
            </div>
        </div>
    )
}

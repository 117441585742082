import { Menu } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";

import useTransformSideMenu from "../../hooks/useTransformSideMenu";

import logo from "../../assets/images/logo.png";

function Sidenav({ color, setVisible, counts }) {
  const { memoizedMenuItems } = useTransformSideMenu({ counts, color, setVisible, type: 'drawer' });

  return (
    <>
      <div className="brand">
        <NavLink to="/" onClick={() => setVisible(false)}>
          <img src={logo} alt="FMBS CRM" />
          <span>FMBS CRM</span>
        </NavLink>
      </div>
      <hr />
      <Menu
        theme="dark"
        mode="inline"
        defaultOpenKeys={['1']}
        items={memoizedMenuItems}
      />
    </>
  );
}

export default Sidenav;

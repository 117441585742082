import appApi from "../../index";
import qs from 'qs';

const endpoint = "recycle-orders";

const list = (url = null, params) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}`, { params: { ...qs.parse(params) } })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getOne = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getOneWithOrderRepair = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/get-one/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const updateOneField = (url = null, id, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .put(`/${endpoint}/${id}`, qs.stringify(formData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const getStatusCounts = (url = null, params = null) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}/get-status/counts`, { params: { ...qs.parse(params) } })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const updateOne = (url = null, id, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .put(`/${endpoint}/notification/update/${id}`, qs.stringify(formData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const deleteOne = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .delete(`/${endpoint}/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const sendResendOrderConfirmation = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/notification/resend-order-confirm`, { orderId: id })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const sendOrderReminder = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/notification/send-order-reminder`, { orderId: id })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const sendOrderReminderBulk = (url = null, ids) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/notification/send-order-reminder-bulk`, { orderId: ids })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const markAsCompleted = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/jobs/mark-as-completed`, { orderId: id })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const markAsReturn = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/jobs/mark-as-return`, { orderId: id })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }

      });
  });
}

const setAsUnPaid = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/jobs/set-as-unpaid`, { orderId: id })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }

      });
  });
};

const updateAddress = (url = null, order_id, addressType, userId, requestBody) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/update-recycle-address`, { orderId: order_id, addressType: addressType, userId: userId, ...requestBody })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};


const collectionOrder = (url = null, order_id, is_collection, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/collection-order`, { order_id: order_id, is_collection: is_collection, formData })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }

      });
  });
};


const markCollectionOrder = (url = null, order_id, is_collection, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/mark-collection-order`, { order_id: order_id, is_collection: is_collection, collection_date: formData?.collection_date })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }

      });
  });
};

const markRemoveCollectionOrder = (url = null, order_id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/recycle-mark-remove-collection-order`, { order_id: order_id })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }

      });
  });
};

const ordersCount = (url = null, params) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/recycle-orders-count`, { params: { ...qs.parse(params) } })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

export default {
  list,
  getOne,
  getOneWithOrderRepair,
  getStatusCounts,
  updateOne,
  deleteOne,
  ordersCount,
  sendResendOrderConfirmation,
  sendOrderReminder,
  sendOrderReminderBulk,
  markAsCompleted,
  markAsReturn,
  setAsUnPaid,
  updateAddress,
  collectionOrder,
  markCollectionOrder,
  markRemoveCollectionOrder,
  updateOneField
};

import React, { useMemo, useCallback } from 'react';
import { Badge, Tooltip, Typography } from 'antd';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import styles from '../components/layout/layout.module.scss';

import { SiteOptions } from '../components/layout/util/common';
import menu from '../components/layout/util/menu';
import IconBase from '../components/Icon/IconBase';

const { Text } = Typography;

export default function useTransformSideMenu({ counts, color, setVisible = null, type = null }) {
  const baseUrl = useSelector((state) => state.auth.base_url);

  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  const generateMenuItem = (name, text = null, iconWrapColor, icon, count) => {
    return {
      label: (
        <Tooltip title={text ?? _.startCase(_.toLower(name))} placement="right">
          <NavLink className="py-0 mb-2" to={`/${name}`}>
            <div className={styles.links}>
              <Badge count={count}>
                <Text
                  className={["icon mr-0 font-weight-bold", styles.iconWrap].join(' ')}
                  style={{
                    background: page === name ? color : iconWrapColor || '',
                  }}
                >
                  {icon}
                </Text>
              </Badge>
              <Text
                className="text-center extra-small py-1"
                strong={!!iconWrapColor}
              >
                {text ?? _.startCase(_.toLower(name))}
              </Text>
            </div>
          </NavLink>
        </Tooltip>
      ),
      key: name,
    };
  };

  const generateMenuItem2 = (name, text = null, iconWrapColor, icon, count) => {
    return {
      label: (
        <NavLink to={`/${name}`} onClick={() => setVisible(false)} title={_.startCase(_.toLower(name))}>
          <Tooltip title={text ?? _.startCase(_.toLower(name))}>
            <Text
              className="icon"
              style={{
                background: page === name ? color : iconWrapColor || '',
              }}
            >
              <Badge count={count}>
                {icon}
              </Badge>
            </Text>
            <Text
              className="label"
              strong={!!iconWrapColor}
            >
              {text ?? _.startCase(_.toLower(name))}
            </Text>
          </Tooltip>
        </NavLink>
      ),
      key: name,
    };
  };

  const transformMenuItems = useMemo(() => {
    // Add the count badge to the menu items
    const badgeCounts = {
      'orders/urgent': counts.urgentOrder,
    };

    return menu.map(item => {
      Object.keys(badgeCounts).forEach(key => {
        if (item.key === key) {
          item.count = badgeCounts[key];
        }
      });

      return item;
    });
  }, [menu, counts]);

  function removeMenuItems(removedMenuItems, menuItems) {
    return menuItems.filter(item => !removedMenuItems.includes(item.key));
  }

  const generateMenuItems = useCallback(() => {
    // const fmbsBaseUrl = SiteOptions.find(site => site.brand === 'FMBS')?.value;
    const tradeBaseUrl = SiteOptions.find(site => site.brand === 'BUSINESS')?.value;

    const baseMenuItems = transformMenuItems.map(item => {
      const Icon = item.icon;

      if (type === 'drawer')
        return generateMenuItem2(
          item.key,
          item.label,
          item?.iconWrapColor,
          (<IconBase component={Icon} iconColor={item?.iconColor} />),
          item?.count
        );

      return generateMenuItem(
        item.key,
        item.label,
        item?.iconWrapColor,
        (<IconBase component={Icon} iconColor={item?.iconColor} />),
        item?.count
      );
    });

    let filteredMenuItems = baseMenuItems;
    if (baseUrl === tradeBaseUrl) {
      const removedMenuItems = ['bulk-repair', 'bulk-recycle'];
      filteredMenuItems = removeMenuItems(removedMenuItems, filteredMenuItems);
    }

    if (baseUrl !== tradeBaseUrl) {
      const removedMenuItems = ['customers'];
      filteredMenuItems = removeMenuItems(removedMenuItems, filteredMenuItems);
    }

    return filteredMenuItems;
  }, [baseUrl, SiteOptions, menu, counts]);

  const memoizedMenuItems = useMemo(() => generateMenuItems(), [generateMenuItems]);

  return { memoizedMenuItems };

}

import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Spin, Table, Typography, message, Modal } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import styles from '../Orders.module.scss';

import NotificationTemplateApi from '../../../apis/order-management/NotificationTemplateApi';
import OrdersApi from '../../../apis/order-management/recycle-order/RecycleOrdersApi';
import StatusBadge from '../../../components/StatusBadge';
import { getAllWFCStatus, getWFCStatusByValue } from '../util/common';

const { Option } = Select;
const { Text } = Typography;
const { confirm } = Modal;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function WFCNoteUpdate({ orderId, employees, baseUrl, setKey, handleReset, isRefresh }) {
    const [form] = Form.useForm();
    const validateMessages = {
        required: '${label} is required!',
    };

    const columns = [
        {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
            render: text => <Text style={{ whiteSpace: 'break-spaces' }}>{text}</Text>,
            width: 700
        },
        {
            title: 'Staff',
            dataIndex: 'employee',
            key: 'employee',
            render: (employee) => {
                return employee?.emp_name ?? "-";
            },
        },
        {
            title: 'Status',
            dataIndex: 'wfc_status',
            key: 'wfc_status',
            render: (value) => {
                const statusObj = getWFCStatusByValue(value);
                return (<StatusBadge value={value} color={statusObj?.status} label={statusObj?.label} />);
            },
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: text => (text ? <Text type="secondary" title={dayjs(text).format('MMMM Do YYYY, h:mm:ss a')}>{dayjs(text).format('DD/MM/YYYY h:mm a')}</Text> : "-"),
        },
    ];

    const [orderObj, setOrderObj] = useState({
        order: null,
        loading: false,
        isSubmitting: false,
        refreshKey: 0
    });

    const fetchOrder = async () => {
        setOrderObj(prevState => ({ ...prevState, loading: true }));
        try {
            const response = await OrdersApi.getOne(baseUrl, orderId);
            setOrderObj(prevState => ({ ...prevState, order: response, loading: false }));
        } catch (error) {
            setOrderObj(prevState => ({ ...prevState, loading: false }));
        }
    };

    useEffect(() => {
        fetchOrder();
    }, [orderObj.refreshKey]);

    const onFinish = async (values) => {
        const orderId = orderObj.order.id;
        setOrderObj(prevState => ({ ...prevState, isSubmitting: true }));

        let sendNotificationForWFCPending = false;
        const confirmSendNotification = () => {
            return new Promise((resolve) => {
                confirm({
                    title: 'Do you want to send a sms notification to the customer?',
                    content: 'This will send a sms notification to the customer.',
                    icon: <ExclamationCircleOutlined />,
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk() {
                        resolve(true);
                    },
                    onCancel() {
                        resolve(false);
                    },
                });
            });
        };

        try {
            // wait for the user to confirm the action for send sms notification when wfc_status is set to '2'
            if (values?.wfc_status == 2) {
                sendNotificationForWFCPending = await confirmSendNotification();
            }

            const response = await NotificationTemplateApi.wfcNoteUpdate(baseUrl, orderId, {
                note: values.note ?? null,
                employee_id: values.employee_id ?? null,
                wfc_status: values.wfc_status ?? null,
                send_notification: sendNotificationForWFCPending ?? null,
                type: 'recycle'
            });

            if (response) {
                message.success(`${orderId + " -" ?? ""} WFC is updated`);
                if (setKey) setKey(prevState => prevState + 1);
                if (handleReset) handleReset();
                setOrderObj(prevState => ({ ...prevState, isSubmitting: false }));
                if (isRefresh) setOrderObj(prevState => ({ ...prevState, refreshKey: prevState.refreshKey + 1 }));
            }
        } catch (error) {
            setOrderObj(prevState => ({ ...prevState, isSubmitting: false }));
            message.error('Something went wrong!');
        }
    };

    return (
        <>
            {orderObj.order && !orderObj.loading ?
                <div className='d-flex flex-column' style={{ height: '88vh' }}>
                    <div className='overflow-y-scroll flex-grow-1 w-100'>
                        <div style={{ maxHeight: 'calc(100vh - 120px)' }}>
                            <Table
                                size='small'
                                dataSource={[
                                    ...(orderObj.order?.wfc_notes_old || []).map((note, index) => ({
                                        ...note,
                                        key: index,
                                    })),
                                    ...(orderObj.order?.wfc_notes || []).map((note, index) => ({
                                        ...note,
                                        key: index,
                                    })),
                                ]}
                                columns={columns}
                                pagination={false}
                            />
                        </div>
                    </div>
                    <div className={`d-flex justify-start flex-shrink-0 overflow-y-auto w-100 py-4 mt-4 height-auto ${styles.bgSecondary}`}>
                        <Form
                            form={form}
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                            layout='vertical'
                            className='w-100'
                        >
                            <Form.Item
                                name="note"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Missing note',
                                    },
                                ]}
                            >
                                <Input.TextArea placeholder="Note" rows={4} />
                            </Form.Item>
                            <div className={styles.baseLineWrap}>
                                {employees &&
                                    <Form.Item
                                        name='employee_id'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Missing staff',
                                            },
                                        ]}
                                        className='w-100 mr-3'
                                    >
                                        <Select
                                            placeholder="Select a staff"
                                            placement="topLeft"
                                        >
                                            {employees.map((item, idx) => (
                                                <Option key={idx} value={item.id}>
                                                    {item.emp_name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                }
                                <Form.Item
                                    name='wfc_status'
                                    className='w-100 mr-3'
                                >
                                    <Select
                                        placeholder="Select a status"
                                        placement="topLeft"
                                    >
                                        {(getAllWFCStatus.slice(1)).map((item, idx) => (
                                            <Option key={idx} value={item.value}>{item.label}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Button type="primary" size='small' htmlType="submit" block loading={orderObj.isSubmitting} className='w-100'>
                                    Add Note
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
                :
                <Spin indicator={antIcon} />
            }
        </>
    )
}

import { CheckOutlined, CloseOutlined, LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import {
  Card,
  Col,
  Row,
  Spin,
  Table,
  Button,
  Form,
  Input,
  Typography,
  Select,
  message,
} from "antd";
import qs from 'qs';
import React, { useState, useEffect, useCallback, useRef } from "react";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.locale('en-gb');

import styles from './PromoCode.module.scss';

import { useSelector } from 'react-redux';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import QuickEdit from "./Components/QuickEdit";
import SelectedOptions from "./Components/SelectedOptions";
import useLogin from '../../hooks/useLogin';
import PromoCodeApi from '../../apis/order-management/PromoCodeApi';
import ViewPromoCode from './Components/ViewPromoCode';
import { SiteOptions } from '../../components/layout/util/common';

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)
dayjs.tz.setDefault('Europe/London');

const LoadingIcon = <LoadingOutlined style={{ fontSize: '2rem' }} spin />;
const { Text } = Typography;


export default function PromoCode(props) {

  useDocumentTitle(props.title);
  const { roles } = useSelector((state) => state.auth.user[0])
  const baseUrl = useSelector((state) => state.auth.base_url)
  const [form] = Form.useForm();

  const [tableTab, setTableTab] = useState(0);
  const [PromoCode, setPromoCode] = useState([]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(0);
  const [filterPromoCode, setfilterPromoCode] = useState(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 100,
    },
    query: "",
    status: null,
    wfc_status: null,
    is_paid: null
  });

  const queryRef = useRef('');

  const [selectedValues, setSelectedValues] = useState({
    selectedRowKeys: [],
    batchRecord: []
  });

  const getQueryParams = (params) => ({
    current_page: params.pagination?.current,
    per_page: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });

  const [visible, setVisible] = useState(false);
  const [visiblePromoCode, setVisiblePromoCode] = useState(false);
  const [activePromoCode, setActivePromoCode] = useState(null);

  const rowSelection = {
    selectedRowKeys: selectedValues.selectedRowKeys,
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, selectedRows) => setSelectedValues({ selectedRowKeys, batchRecord: selectedRows }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE
    ]
  }


  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Discount",
      dataIndex: "details",
      render: (details) => {
        return '£' + details?.discount;
      }
    },
    {
      title: "Email",
      key: "details",
      dataIndex: "details",
      render: (details) => {
        return details?.email;
      }
    },
    {
      title: "Usage",
      dataIndex: "usages_left",
      key: "usages_left",
    },
    {
      title: "Is Available",
      dataIndex: "is_available",
      key: "is_available",
      render: (is_available) => (
        is_available ? (
          <CheckOutlined style={{ color: 'green' }} />
        ) : (
          <CloseOutlined style={{ color: 'red' }} />
        )
      ),
    },
    {
      title: "Expired At",
      key: "expired_at",
      dataIndex: "expired_at",
      render: text => (text ? <Text type="secondary" title={dayjs(text).format('MMMM Do YYYY, h:mm:ss a')}>{dayjs(text).format("DD-MM-YYYY")} {dayjs(text).format("h:mm:ss a")}</Text> : "-"),
    },
    {
      title: "Created At",
      key: "created_at",
      dataIndex: "created_at",
      render: text => (text ? <Text type="secondary" title={dayjs(text).format('MMMM Do YYYY, h:mm:ss a')}>{dayjs(text).format("DD-MM-YYYY")} {dayjs(text).format("h:mm:ss a")}</Text> : "-"),
    },
  ];

  const fetchPromoCode = useCallback((isCancelled) => {
    setLoading(true);
    PromoCodeApi.list(baseUrl, qs.stringify(getQueryParams(tableParams)))
      .then(({ data: promoCodeData }) => {

        if (!isCancelled) {
          setPromoCode(promoCodeData.data);
          setTableParams((prevTableParams) => ({
            ...prevTableParams,
            query: getValidSearchText(tableParams.query),
            pagination: {
              ...prevTableParams.pagination,
              current: promoCodeData.current_page,
              pageSize: promoCodeData.per_page,
              total: promoCodeData.total,
            },
          }));
          setLoading(false);
        }
      })

  }, [baseUrl, tableParams, getQueryParams]);

  function getValidSearchText(query, searchText) {
    const searchBoxText = queryRef.current?.input?.value;
    if (searchText === "") {
      if (searchBoxText === "") return "";
      return query;
    }
    return searchText;
  }

  useEffect(() => {
    let isCancelled = false
    fetchPromoCode(isCancelled);

    return () => {
      isCancelled = true
    }
  }, [key, JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setPromoCode([]);
    }
  };

  const showModalCreate = () => {
    setVisible(true);
    setActivePromoCode(null);
  };

  const showModal = (item) => {
    setVisiblePromoCode(true);
    setActivePromoCode(item);
  };

  const handleReset = () => {
    setActivePromoCode(null);
    setVisible(false);
    setKey(prevState => prevState + 1);
  }

  const handleCancel = () => {
    setVisible(false);
    setActivePromoCode(null);
  };

  const handleCancelPromoCode = () => {
    setVisiblePromoCode(false);
    setActivePromoCode(null);
  };


  const rowsUnCheckHandler = () => {
    setSelectedValues({
      selectedRowKeys: [],
      batchRecord: []
    })
  }


  const { handleChangeLogin } = useLogin(setLoading);


  const pageLoading = () => {
    setTableTab(0);
    setKey(prevKey => prevKey + 1)
    setfilterPromoCode(null);
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      userType: 'all',
    }));
    form.setFieldsValue({
      filterTable: null,
    });
  };


  const search = value => {

    const filterTable = PromoCode.filter(o =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );

    setfilterPromoCode(filterTable);
  };
  
  const handleBaseUrlChange = async (value) => {
    const result = await handleChangeLogin(value);
    if (result.success) {
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
      setKey(prevKey => prevKey + 1)
    }
    else {
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
    }
  };



  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <div className="site-page-header-ghost-wrapper">

              <Card
                bordered={false}
                className={`criclebox tablespace ${styles.table}`}


                styles={{
                  header: {
                    padding: 0,
                    border: 'none'
                  }
                }}
              >
                <Row justify="space-between" align="top">
                  <Col md={22} className='d-inline-flex mb-4'>
                    <Button
                      icon={<SyncOutlined />}
                      loading={loading}
                      onClick={pageLoading}
                      className="mr-2 px-2"
                    />
                    <Select
                      value={baseUrl}
                      onChange={handleBaseUrlChange}
                      className="mr-4"
                      style={{ width: 260 }}
                      options={SiteOptions}
                      disabled={loading}
                    />
                    <Form
                      form={form}
                    >
                      <Form.Item name="filterTable"  >
                        <Input.Search
                          ref={queryRef}
                          placeholder="Search by Code, Email"
                          className={`w-100 ${styles.search}`}
                          onSearch={search}
                          allowClear
                          disabled={loading}
                        />
                      </Form.Item>

                    </Form>
                  </Col>

                  <Col md={2} className='d-inline-flex mb-4'>


                    <Button
                      className='mx-2'
                      form='reason-create'
                      type="primary"
                      onClick={() => showModalCreate(null)}
                      loading={loading}
                    >
                      Create Promo Code
                    </Button>

                  </Col>

                </Row>
                <div className="table-responsive">

                  <SelectedOptions
                    selectedRowKeys={selectedValues.selectedRowKeys}
                    selectedRows={selectedValues.batchRecord}
                    setIndex={setKey}
                    rowsUnCheckHandler={rowsUnCheckHandler}
                  />

                  <Table
                    columns={columns}
                    rowKey={(record) => record.id}
                    rowSelection={rowSelection}
                    dataSource={filterPromoCode === null ? PromoCode : filterPromoCode}
                    loading={{ spinning: loading, indicator: <Spin size="middle" indicator={LoadingIcon} /> }}
                    pagination={{
                      showSizeChanger: true,
                      showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
                      showQuickJumper: true,
                      pageSizeOptions: [100, 200, 300, 400, 500],
                      defaultPageSize: tableParams.pagination.pageSize,
                      position: ["bottomRight"],
                      ...tableParams.pagination
                    }}
                    className="ant-border-space"
                    size="small"
                    ellipsis
                    responsive
                    onChange={handleTableChange}
                    onRow={(record) => {
                      return {
                        onClick: (_) => {
                          showModal(record)
                        }
                      };
                    }}
                  />
                </div>
              </Card>
            </div>
          </Col>
        </Row>

        {visible &&
          <QuickEdit
            visible={visible}
            handleCancel={handleCancel}
            activePromoCode={activePromoCode}
            handleReset={handleReset}
            roles={roles}
            setIndex={setKey}
          />
        }

        {visiblePromoCode &&
          <ViewPromoCode
            visible={visiblePromoCode}
            handleCancel={handleCancelPromoCode}
            activePromoCode={activePromoCode}
            handleReset={handleReset}
            roles={roles}
            setIndex={setKey}
          />
        }
      </div>
    </>
  );
}
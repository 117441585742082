import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Button, Col, Form, Modal, Row, Select, message, Drawer, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useSelector } from "react-redux";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ConditionDescriptionApi from '../../../../apis/settings/ConditionDescriptionApi';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;
const { confirm } = Modal;
const { Text } = Typography;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickEdit({ visible, handleCancel, activeCondition, handleReset, productCategory, categoryCondition, roles, setIndex }) {
    const validateMessages = {
        required: '${label} is required!',
    };

    const [editorData, setEditorData] = useState((activeCondition?.description) ?? null);
    const baseUrl = useSelector((state) => state.auth.base_url)
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (activeCondition) {
            form.setFieldsValue({
                product_category_id: activeCondition?.product_category_id,
                device_condition_id: activeCondition?.device_condition_id,
            });
        }
    }, [activeCondition, form]);

    const onFinish = async (values) => {
        try {

            const formData = new FormData();
            formData.append('device_condition_id', values.device_condition_id);
            formData.append('product_category_id', values.product_category_id);
            formData.append('description', editorData);

            if (activeCondition?.id) {
                formData.append('id', activeCondition.id);
            }

            try {

                const updateCondition = await ConditionDescriptionApi.createCondition(baseUrl, formData);
                if (updateCondition) {
                    message.success('Condition Description is Updated!');
                    setIndex(prevIndex => prevIndex + 1);
                    form.resetFields();
                    handleReset();
                }
            } catch (error) {
                message.error('Something went wrong!');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleCondition = async () => {
        const formData = new FormData();
        formData.append('selectedRowIds', [activeCondition.id]);

        try {
            const deleteCondition = await ConditionDescriptionApi.deleteCondition(baseUrl, formData, activeCondition.id);
            if (deleteCondition) {
                message.success('Condition Description is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form.resetFields();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleCondition();
            },
            onCancel() {
                //
            },
        });
    }

    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }

    return (
        <Drawer
            push={false}
            key="notification-details-model"

            title={<Row key="row"
                align="middle"
                justify="space-between"
            >
                <Col md={24} className='d-flex' style={{ justifyContent: 'space-between' }}>
                    <Text>Condition Description Form</Text>

                    <div className='d-flex'>
                        <Button key="cancel" onClick={handleCancel}>Cancel</Button>
                        <Button
                            className='mx-2'
                            form='condition-update'
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {activeCondition?.id ? "Update" : "Create"}
                        </Button>
                    </div>
                </Col>
            </Row>}
            open={visible}
            onClose={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={1000}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeCondition && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>
                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form}
                id="condition-update"
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
            >
                <Row gutter={[24, 0]}>
                    <Col span={23}>
                        <Row gutter={[24, 0]}>
                            <Col span={8}>
                                <Form.Item name="product_category_id" label="Product Category" rules={[{ required: true }]} labelCol={{ span: 24 }}>

                                    <Select
                                        optionLabelProp="children"
                                        placeholder="Select Category"
                                    >
                                        {(productCategory?.data ?? []).map((item, idx) => (
                                            <Option key={idx} value={item.id}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col> <Col span={8}>
                                <Form.Item name="device_condition_id" label="Condition" rules={[{ required: true }]} labelCol={{ span: 24 }}>

                                    <Select
                                        optionLabelProp="children"
                                        placeholder="Select Condition"
                                    >
                                        {(categoryCondition?.data ?? []).map((item, idx) => (
                                            <Option key={idx} value={item.id}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item name="description" label="Description" labelCol={{ span: 24 }}>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={editorData}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setEditorData(data);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}

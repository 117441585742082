import React from 'react'
import { Card, Table, Typography } from 'antd';

const { Title } = Typography;
export default function SalesTable({ salesData }) {

    const columns = [
        {
            title: 'Date',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Order Count',
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: 'Shipping Order',
            dataIndex: 'countFastShipping',
            key: 'countFastShipping',
        },
        {
            title: 'Shipping Charge',
            dataIndex: 'valueFastShipping',
            key: 'valueFastShipping',
        },
        {
            title: 'Priority Order',
            dataIndex: 'countPriority',
            key: 'countPriority',
        },
        {
            title: 'Priority Charge',
            dataIndex: 'valuePriority',
            key: 'valuePriority',
        },
        {
            title: 'Amount',
            dataIndex: 'value',
            key: 'value',
        },
    ];

    return (
        <Card bordered={false} className="criclebox">
            <Title level={4} strong>Current Month Revenue</Title>
            <Table dataSource={salesData?.table?.map((item, index) => ({
                ...item,
                key: item.key ?? index,
            }))} columns={columns} pagination={false} className="criclebox" />
        </Card>
    )
}

import appApi from "../index";
import qs from 'qs';

const endpoint = "products"
const headers = {
  'Content-Type': 'multipart/form-data',
};

const list = (url = null, type) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}`, {
        params: {
          type
        },
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const createProduct = (url = null, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}`, formData, { headers })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const deleteProduct = (url = null, formData, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .delete(`/${endpoint}/${id}`, formData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};


export default {
  list,
  createProduct,
  deleteProduct
};
// Orders
const getAllOrderStatus = [
    { value: null, label: "All", status: "", color: '' },
    { value: 1, label: "Processing", status: "gold", color: 'gold' },
    { value: 2, label: "WFC", status: "cyan", color: 'cyan' },
    { value: 3, label: "Ready to collect", status: "blue", color: 'blue' },
    { value: 4, label: "Collected", status: "green", color: 'green' },
    { value: 5, label: "Posted", status: "green", color: 'green' },
    { value: 7, label: "Cancelled", status: 'default', color: '#ccc' },
    { value: 8, label: "Return", status: "default", color: '#000' },
];

const statusRemovableList = [];
const filteredOrderStatus = getAllOrderStatus.filter(element => !statusRemovableList.includes(element.value));

const getAllPayStatus = [
    { value: 0, label: "Un Paid", color: "error" },
    { value: 1, label: "Partially Paid", color: "gold" },
    { value: 2, label: "Paid", color: "success" },
];

// WFC
const getAllWFCStatus = [
    { value: null, label: "All", status: "", color: '' },
    { value: 1, label: "Open", status: "error", color: "red" },
    { value: 2, label: "Pending", status: "gold", color: "gold" },
    { value: 3, label: "Manager", status: "default", color: "#000" },
    { value: 4, label: "Solved", status: "blue", color: "blue" },
    { value: 5, label: "Close", status: "success", color: "green" },
];

const getOrderStatusByValue = (id) => ((getAllOrderStatus.slice(1)).find(({ value }) => (value === id)));
const getPayStatusByValue = (id) => (getAllPayStatus.find(({ value }) => (value === id)));
const getWFCStatusByValue = (id) => (getAllWFCStatus.find(({ value }) => (value === id)));

export {
    getAllOrderStatus,
    filteredOrderStatus,
    getOrderStatusByValue,
    getAllPayStatus,
    getPayStatusByValue,
    getAllWFCStatus,
    getWFCStatusByValue
};
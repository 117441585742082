import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Typography, Modal, Row, message, Drawer, List } from 'antd';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { useSelector } from "react-redux";
import PromoCodeApi from '../../../apis/order-management/PromoCodeApi';
import ListItem from '../../Orders/Components/ListItem';

dayjs.extend(utc)
dayjs.extend(timezone)

const { confirm } = Modal;
const { Text } = Typography;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function ViewPromoCode({ visible, handleCancel, activePromoCode, handleReset, roles, setIndex, rolesList }) {
    const validateMessages = {
        required: '${label} is required!',
    };

    const [form] = Form.useForm();

    const baseUrl = useSelector((state) => state.auth.base_url)

    const handleDeleteCustomer = async () => {

        try {
            const deleteLogs = await PromoCodeApi.deleteOne(baseUrl, activePromoCode.id);
            if (deleteLogs) {
                message.success('Promo Code is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form.resetFields();
                handleCancel();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }



    const handleExpirCode = async () => {

        try {
            const deleteLogs = await PromoCodeApi.expire(baseUrl, activePromoCode?.code);
            if (deleteLogs) {
                message.success('Promo Code is expired !');
                setIndex(prevIndex => prevIndex + 1)
                form.resetFields();
                handleCancel();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `Selected Item`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteCustomer();
            },
            onCancel() {
                //
            },
        });
    }


    const showConfirmExpired = () => {
        confirm({
            title: 'Are you sure this item?',
            icon: <ExclamationCircleOutlined />,
            content: `You want to set this code as expired`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleExpirCode();
            },
            onCancel() {
                //
            },
        });
    }


    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }

    return (
        <Drawer
            push={false}
            key="notification-details-model"
            title={[
                <Row key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>Promo Code</Col>
                    <div>
                        <Button key="cancel" onClick={handleCancel}>Cancel</Button>
                        {(activePromoCode?.is_available) &&
                            <>
                                <Button key="expired" className='mx-3' danger onClick={showConfirmExpired}>Expire Code</Button>
                            </>
                        }
                    </div>
                </Row>
            ]}
            open={visible}
            onClose={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={890}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                    </Col>

                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form}
                id="delivery-update"
                name="nest-messages"
                validateMessages={validateMessages}
            >
                <Row gutter={16} >
                    {activePromoCode?.created_at &&
                        <Col span={8} className={`$`}>
                            <List
                                size="small"
                                header={<Text>Promocode Info</Text>}
                                bordered
                                dataSource={[
                                    <ListItem key={0}
                                        keyText="Multi use : "
                                        valueText={
                                            activePromoCode?.multi_use ? (
                                                <CheckOutlined style={{ color: 'green' }} />
                                            ) : (
                                                <CloseOutlined style={{ color: 'red' }} />
                                            )
                                        }
                                    />,
                                    <ListItem key={1}
                                        keyText="Bound to user : "
                                        valueText={
                                            activePromoCode?.bound_to_user ? (
                                                <CheckOutlined style={{ color: 'green' }} />
                                            ) : (
                                                <CloseOutlined style={{ color: 'red' }} />
                                            )
                                        }
                                    />,
                                    < ListItem key={2}
                                        keyText="E-Mail : "
                                        valueText={`${activePromoCode?.details?.email ?? '-'}`}
                                        valueCopyable
                                    />,
                                    <ListItem key={3}
                                        keyText="Created At : "
                                        valueText={`${activePromoCode?.created_at ? dayjs(activePromoCode?.created_at).format("DD-MM-YYYY") : "-"}`}
                                    />,
                                    <ListItem key={4}
                                        keyText="Updated At : "
                                        valueText={`${activePromoCode?.updated_at ? dayjs(activePromoCode?.updated_at).format("DD-MM-YYYY") : "-"}`}

                                    />,
                                    <ListItem key={5}
                                        keyText="Expired At : "
                                        valueText={`${activePromoCode?.expired_at ? dayjs(activePromoCode?.expired_at).format("DD-MM-YYYY") : "-"}`}

                                    />,
                                ]}
                                renderItem={item => <List.Item>{item}</List.Item>}
                            />
                        </Col>
                    }

                    <Col span={8}>
                        <List
                            size="small"
                            header={<Text>Promocode</Text>}
                            bordered
                            dataSource={[
                                <ListItem key={0}
                                    keyText="Promocode Id"
                                    valueText={`${activePromoCode?.id ?? '-'}`}
                                />,
                                <ListItem key={1}
                                    keyText="Code : "
                                    valueText={`${activePromoCode?.code ?? '-'}`}
                                    valueCopyable
                                />,
                                < ListItem key={2}
                                    keyText="Discount : "
                                    valueText={`${activePromoCode?.details?.discount ?? '-'}`}
                                />,
                                <ListItem key={3}
                                    keyText="Usage Left : "
                                    valueText={`${activePromoCode?.usages_left ?? '-'}`}
                                />,
                            ]}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    </Col>

                    <Col span={8}>
                        <List
                            size="small"
                            header={<Text>Details</Text>}
                            bordered
                            dataSource={[
                                <ListItem key={0}
                                    keyText="Note"
                                    valueText={`${activePromoCode?.details?.data?.note ?? '-'}`}
                                    valueCopyable
                                />,
                                <ListItem key={1}
                                    keyText="Intent : "
                                    valueText={`${activePromoCode?.details?.data?.intent ?? '-'}`}
                                    valueCopyable
                                />,
                                < ListItem key={2}
                                    keyText="Type : "
                                    valueText={`${activePromoCode?.details?.data?.message ?? '-'}`}
                                    valueCopyable
                                />,
                                !activePromoCode?.created_at && (
                                    <ListItem key={3}
                                        keyText="Expired At : "
                                        valueText={`${activePromoCode?.expired_at ? dayjs(activePromoCode?.expired_at).format("DD-MM-YYYY") : "-"}`}

                                    />
                                ),
                            ]}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    </Col>

                </Row>
            </Form>
        </Drawer>
    )
}

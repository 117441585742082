import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Input, Typography, Spin, Button, Row, Col, List, Flex, message } from "antd";
import { FaExternalLinkAlt } from "react-icons/fa";
import { PhoneOutlined } from "@ant-design/icons";

import smsApi from "../../apis/send-message/SMSApi";
import GeminiAiField from "../components/fields/gemini-ai-field";

const { TextArea } = Input;
const { Text, Paragraph } = Typography;

const SendMessage = ({ baseUrl, activeOrder }) => {
  const [messages, setMessages] = useState([]);
  const [_timestamp, setTimestamp] = useState(null);

  const [charCount, setCharCount] = useState(0);
  const [textMessage, setTextMessage] = useState("");
  const [pricePerSegment, setPricePerSegment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);

  const GSM_SINGLE_LIMIT = 160;
  const GSM_SEGMENT_LIMIT = 153;

  useEffect(() => {
    const fetchSMSPricing = async () => {
      try {
        setLoading(true);
        const data = await smsApi.getSMSPricing(baseUrl);
        setPricePerSegment(data.price_per_segment);
      } catch (error) {
        console.error("Error fetching SMS pricing:", error);
        message.error("Failed to fetch SMS pricing. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchSMSPricing();
  }, []);

  const chatGPTContent = (content) => {
    if (!content || content.length === 0) {
      return;
    }

    setMessages(content);

    const lastMessage = content[content.length - 1];
    const messageContent = lastMessage.content || lastMessage.parts || "";
    setTextMessage(messageContent);
    setCharCount(messageContent.length);
  }

  const handleTextChange = (e) => {
    const text = e.target.value;
    setTextMessage(text);
    setCharCount(text.length);
  };

  const calculateSegments = useCallback(() => {
    return charCount <= GSM_SINGLE_LIMIT
      ? 1
      : Math.ceil(charCount / GSM_SEGMENT_LIMIT);
  }, [charCount]);

  const handleSendMessage = async () => {
    const phoneNumber = activeOrder?.phone_number || activeOrder?.user?.phone_no || activeOrder?.user?.company?.phone_number || activeOrder?.user?.phone_number;
    if (!phoneNumber || !textMessage) {
      message.error("Phone number and message cannot be empty.");
      return;
    }

    try {
      setSending(true);
      const response = await smsApi.sendMessage(baseUrl, phoneNumber, textMessage);
      if (response) {
        message.success("Message sent successfully!");
      }
    } catch (error) {
      message.error(error.message || "Failed to send the message.");
    } finally {
      setSending(false);
    }
  };

  const segments = calculateSegments();
  const estimatedCost = useMemo(() => {
    return pricePerSegment ? segments * pricePerSegment : 0;
  }, [pricePerSegment, segments, calculateSegments]);

  const typeOfSummary = useMemo(() => {
    return segments > 1 ? "danger" : (textMessage.length === 0 ? null : "success");
  }, [segments, textMessage]);

  const customerName = useMemo(() => {
    return activeOrder?.user?.first_name || activeOrder?.first_name || activeOrder?.user?.last_name || activeOrder?.last_name || activeOrder?.user?.customer_name || "";
  }, [activeOrder]);

  const phoneNumber = useMemo(() => {
    return activeOrder?.phone_number || activeOrder?.user?.phone_no || activeOrder?.user?.company?.phone_number || activeOrder?.user?.phone_number || "";
  }, [activeOrder]);

  return (
    <Row gutter={16}>
      <Col span={24}>
        <GeminiAiField
          content={messages}
          setContent={chatGPTContent}
          setTimestamp={setTimestamp}
          initialPrompt={`Write a customer short SMS for the Royal Mail Next Door Collection reminder. The customer's name is ${customerName}. The Twilio SMS character limit is 160.`}
          notResetFieldAfterSubmit
        />
      </Col>
      <Col span={16}>
        <TextArea
          rows={5}
          placeholder="Type your SMS here..."
          value={textMessage}
          onChange={handleTextChange}
          maxLength={1600}
        />
        <Paragraph className={`mt-2 ${phoneNumber ? 'text-primary' : 'text-danger'}`} strong><PhoneOutlined /> {phoneNumber}</Paragraph>
      </Col>
      <Col span={8}>
        <List
          size="small"
          header="Summary of SMS"
          bordered
          dataSource={[
            <Flex key="total_characters" className="w-100" justify="space-between">
              <Text>Total Characters:</Text>
              <Text>{charCount}</Text>
            </Flex>,
            <Flex key="number_of_segments" className="w-100" justify="space-between">
              <Text>Number of Segments:</Text>
              <Text strong type={typeOfSummary}>{segments}</Text>
            </Flex>,
            <Flex key="estimated_cost" className="w-100" justify="space-between">
              <Text>Estimated Cost:</Text>
              {loading ? (
                <Spin size="small" />
              ) : (
                <Text strong type={typeOfSummary}>£{estimatedCost.toFixed(2)}</Text>
              )}
            </Flex>
          ]}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />

        <Button
          type="link"
          href="https://www.twilio.com/docs/glossary/what-sms-character-limit"
          target="_blank"
          icon={<FaExternalLinkAlt />}
        >
          Learn more about SMS character limit
        </Button>

        <Button
          type="primary"
          onClick={handleSendMessage}
          loading={sending}
          block
          className="mt-3"
          disabled={loading || message.length === 0 || phoneNumber.length === 0}
        >
          Send Message
        </Button>
      </Col>
    </Row>
  );
};

export default SendMessage;
import React from 'react'
import { Card, Table, Typography } from 'antd';

const { Title } = Typography;
export default function PaymentTable({ paymentData }) {

    const columns = [
        {
            title: 'Date',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Stripe (Card)',
            dataIndex: 'stripe',
            key: 'stripe',
        },
        {
            title: 'Paypal',
            dataIndex: 'paypal',
            key: 'paypal',
        },
        {
            title: 'Klarna',
            dataIndex: 'klarna',
            key: 'klarna',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
    ];

    return (
        <Card bordered={false} className="criclebox">
            <Title level={4} strong>Payment Status</Title>
            <Table dataSource={paymentData?.map((item, index) => ({
                ...item,
                key: item.key ?? index,
            }))} columns={columns} pagination={false} />
        </Card>
    )
}

import React from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";

import styles from './layout.module.scss';

import useTransformSideMenu from "../../hooks/useTransformSideMenu";

import logo from "../../assets/images/logo.png";

function Sidenav({ color, counts }) {
  const { memoizedMenuItems } = useTransformSideMenu({ counts, color });

  return (
    <>
      <div className={"brand " + styles.brand}>
        <NavLink to="/">
          <img src={logo} alt="FMBS CRM" />
        </NavLink>
      </div>
      <hr className="my-2" />
      <Menu
        theme="dark"
        mode="inline"
        defaultOpenKeys={['1']}
        items={memoizedMenuItems}
      />
    </>
  );
}

export default Sidenav;

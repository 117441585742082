import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Select, message, Drawer, Checkbox } from 'antd';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { useSelector } from "react-redux";
import { getAllWFCStatus } from '../../Orders/util/common';
import NotificationTemplateApi from '../../../apis/order-management/NotificationTemplateApi';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;
const { confirm } = Modal;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickEdit({ visible, handleCancel, activeNotification, handleReset, roles, setIndex }) {
    const validateMessages = {
        required: '${label} is required!',
    };

    const baseUrl = useSelector((state) => state.auth.base_url)
    const [formOptions, setFormOptions] = useState({
        sources: [
            { value: 'order', label: 'Order' },
            { value: 'recycle', label: 'Recycle' },
        ],
        status: [
            { value: 'new', label: 'New' },
            { value: 'open', label: 'Open' },
            { value: 'pending', label: 'Pending' },
            { value: 'hold', label: 'Hold' },
            { value: 'solved', label: 'Solved' },
        ],
        postcodes: null
    });


    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        try {

            const formData = new FormData();
            formData.append('type', values.source);
            formData.append('from', values.from);
            formData.append('scenario', values.scenario);
            formData.append('instruction_for', values.instruction_for ? values.instruction_for : null);
            formData.append('subject', values.subject);
            formData.append('message', values.message);
            formData.append('wfc_status', values.wfc_status);
            formData.append('wfc_note', values.wfc_note);
            formData.append('status', values.status);
            formData.append('editable', values.editable);
            formData.append('allow_attachments', values.allow_attachments);
            formData.append('reasons', values.reasons ? JSON.stringify(values.reasons) : null);
            formData.append('id', activeNotification.id);

            try {

                const updateNotification = await NotificationTemplateApi.createNotification(baseUrl, formData);
                if (updateNotification) {
                    message.success('Notification is Updated!');
                    setIndex(prevIndex => prevIndex + 1);
                    form.resetFields();
                    handleReset();
                }
            } catch (error) {
                console.log(error);

                message.error('Something went wrong!');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleDeleteUser = async () => {
        const formData = new FormData();
        formData.append('selectedRowIds', [activeNotification.id]);

        try {
            const deleteNotification = await NotificationTemplateApi.deleteNotification(baseUrl, formData);
            if (deleteNotification) {
                message.success('Notification is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form.resetFields();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `${activeNotification.userOrName} - ${activeNotification.source}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser();
            },
            onCancel() {
                //
            },
        });
    }


    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }

    return (
        <Drawer
            push={false}
            key="notification-details-model"
            title={[
                <Row key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>{activeNotification?.userOrName}</Col>
                    <div>
                        <Button key="cancel" onClick={handleCancel}>Cancel</Button>
                        <Button
                            className='mx-2'
                            form='delivery-update'
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            Update
                        </Button>
                    </div>
                </Row>
            ]}
            open={visible}
            onClose={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={1000}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeNotification && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>

                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form}
                id="delivery-update"
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
                initialValues={{
                    source: activeNotification?.type ? activeNotification.type : "order",
                    from: activeNotification?.from ? activeNotification.from : null,
                    status: activeNotification?.status ? activeNotification.status : null,
                    wfc_status: activeNotification?.wfc_status ? activeNotification.wfc_status : null,
                    scenario: activeNotification?.scenario ? activeNotification.scenario : null,
                    instruction_for: activeNotification?.instruction_for !== "null" ? activeNotification.instruction_for : '',
                    subject: activeNotification?.subject !== "null" ? activeNotification.subject : '',
                    message: activeNotification?.message ? activeNotification.message : '',
                    wfc_note: activeNotification?.wfc_note !== "null" ? activeNotification.wfc_note : '',
                    reasons: activeNotification?.reasons.length > 0 ? activeNotification?.reasons.map(reasonObj => ({
                        reason: reasonObj.message,
                    })) : [{ reason: '' }],
                    allow_attachments: activeNotification?.allow_attachments === 1 ? true : false,
                    editable: activeNotification?.editable === 1 ? true : false,
                }}
            >
                <Row gutter={[24, 0]}>
                    <Col span={8}>
                        <Form.Item name="source" label="Type of Notification" labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }} rules={[{ required: true }]} initialValue={formOptions.sources[0].value}>

                            <Select
                                optionLabelProp="children"
                            >
                                {(formOptions.sources).map((item, idx) => (
                                    <Option key={idx} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item name="from" label="From" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item name="status" label="Status" rules={[{ required: true }]} initialValue={formOptions.status[0].value}>
                            <Select
                                optionLabelProp="children"
                            >
                                {(formOptions.status).map((item, idx) => (
                                    <Option key={idx} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>


                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item name="wfc_status" label="WFC Status" rules={[{ required: true }]}
                            initialValue={getAllWFCStatus.slice(1)[0].value}>
                            <Select
                                optionLabelProp="children"
                            >
                                {(getAllWFCStatus.slice(1)).filter(item => item.value !== 5).map((item, idx) => (
                                    <Option key={idx} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item name="scenario" label="Scenario" rules={[{ required: true }]} >
                            <Input placeholder="Scenario" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item name="instruction_for" label="Instruction for (Optional)" labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}  >
                            <Input placeholder="Instruction for" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item name="editable" valuePropName="checked" initialValue={true}>
                            <Checkbox>
                                Is Editable Content? (Optional)
                            </Checkbox>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item name="allow_attachments" valuePropName="checked" >
                            <Checkbox>
                                Allow Attachments? (Optional)
                            </Checkbox>
                        </Form.Item>
                    </Col>


                    <Col span={24}>
                        <Form.Item name="subject" label="Subject (Optional)"   >
                            <Input placeholder="Subject" />
                        </Form.Item>
                    </Col>


                    <Col span={24} className='mb-5'>
                        <Form.Item name="message" label="Message" rules={[{ required: true }]}>
                            <Input.TextArea placeholder="Message" rows={6} />
                        </Form.Item>
                        <p className="form-text">
                            If you plan to include reasons, kindly paste the <span >@_type_the_reason_inside_@</span> text in the designated field. The 'Reasons' field is optional.
                            <br />
                            Example:<br />
                            "I believe that <br />
                            <span
                                className="bg-light">@_type_the_reason_inside_@</span><br />because..."
                        </p>
                    </Col>



                    <Col span={24}>
                        <Form.Item name="wfc_note" label="WFC Note (Optional)">
                            <Input.TextArea placeholder="WFC Note" rows={6} />
                        </Form.Item>

                    </Col>

                    <Col span={14}>
                        <span className='mb-4'>Reasons (Optional)</span>
                        <Form.List
                            name="reasons"
                            initialValue={[{ reason: '' }]}
                            rules={[
                                {
                                    validator: async (_, names) => {
                                        if (!names) {
                                            return Promise.reject(new Error('At least 1 reasons'));
                                        }
                                    },
                                },
                            ]}
                        >
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <Form.Item
                                            {...(fields.length === 0 ? { label: 'Reasons' } : {})}
                                            label={fields.length === 0 ? 'Reasons' : ''}
                                            required={false}
                                            key={key}
                                        >
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'reason']} // Access the 'reason' field
                                                fieldKey={[fieldKey, 'reason']}
                                                validateTrigger={['onChange', 'onBlur']}
                                                rules={[
                                                    {
                                                        required: false,
                                                        whitespace: true,
                                                        message: "Please input reason's name or delete this field.",
                                                    },
                                                ]}
                                                noStyle
                                            >
                                                <Input
                                                    placeholder="Enter the reason"
                                                    style={{ width: '60%' }}
                                                />
                                            </Form.Item>
                                            {fields.length > 1 && (
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    style={{ fontSize: '20px', marginLeft: '8px' }}
                                                    onClick={() => remove(name)}
                                                />
                                            )}
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            style={{ width: '40%' }}
                                            icon={<PlusOutlined />}
                                        >
                                            Add reason
                                        </Button>
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>


                </Row>
            </Form>
        </Drawer>
    )
}

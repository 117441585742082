import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Select, message, Drawer, Checkbox, AutoComplete } from 'antd';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { useSelector } from "react-redux";
import PromoCodeApi from '../../../apis/order-management/PromoCodeApi';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;
const { confirm } = Modal;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickEdit({ visible, handleCancel, activePromoCode, handleReset, roles, setIndex, rolesList }) {
    const validateMessages = {
        required: '${label} is required!',
    };

    const [form] = Form.useForm();

    const baseUrl = useSelector((state) => state.auth.base_url)
    const [formOptions, setFormOptions] = useState({
        type: [
            { value: 0, label: 'Customer Fault' },
            { value: 1, label: 'Company Fault' },
        ]
    });


    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        try {


            const formData = new FormData();
            formData.append('reward', values.reward);
            formData.append('expires', values.expires);
            formData.append('usage', values.usage);
            formData.append('intent', values.intent);
            formData.append('message', values.message);
            formData.append('note', values.note);
            formData.append('user_email', values.user_email);

            if (activePromoCode) {
                formData.append('id', activePromoCode?.id);
            }

            try {

                const deletePromoCode = await PromoCodeApi.updatePromoCode(baseUrl, formData);
                if (deletePromoCode) {
                    message.success('Promo Code is Updated!');
                    setIndex(prevIndex => prevIndex + 1);
                    form.resetFields();
                    handleReset();
                }
            } catch (error) {
                console.log(error);

                message.error('Something went wrong!');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleDeletePromoCode = async () => {

        try {
            const deleteLogs = await PromoCodeApi.deleteOne(baseUrl, activePromoCode.id);
            if (deleteLogs) {
                message.success('Promo Code is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form.resetFields();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `Selected Item`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeletePromoCode();
            },
            onCancel() {
                //
            },
        });
    }


    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }

    const [email, setEmail] = useState('');
    const [options, setOptions] = useState([]);

    const fetchSuggestions = async (value) => {
        if (!value) {
            setOptions([]);
            return;
        }

        try {
            const response = await PromoCodeApi.autocompleteEmail(baseUrl, value);
            const transformedOptions = response.map(item => ({
                value: item.value,
            }));
            setOptions(transformedOptions);
        } catch (error) {
            console.error('Error fetching email suggestions:', error);
        }
    };

    const handleChange = (value) => {
        setEmail(value);
        fetchSuggestions(value);
    };
    return (
        <Drawer
            push={false}
            key="notification-details-model"
            title={[
                <Row key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>Promo Code Form</Col>
                    <div>
                        <Button key="cancel" onClick={handleCancel}>Cancel</Button>

                        <Button
                            className='mx-2'
                            form='delivery-update'
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {activePromoCode ? "Update" : 'Create'}
                        </Button>
                    </div>
                </Row>
            ]}
            open={visible}
            onClose={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={890}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activePromoCode && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>

                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form}
                id="delivery-update"
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
                initialValues={{
                    reward: 30,
                    expires: 30,
                    usage: 1,
                    intent: "Discount",
                    message: "Exclusive",
                    note: "Customer Discount",
                }}
            >
                <Row gutter={[24, 0]}>

                    <Col span={23}>
                        <Row gutter={[24, 0]}>
                            <Col span={12}>
                                <Form.Item name="reward" label="Reward" rules={[{ required: true }]}>
                                    <Input name="reward" placeholder="Reward" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="user_email"
                                    label="E-Mail Address"
                                    rules={[
                                        { required: true, message: 'Please input your email address!' },
                                        {
                                            type: 'email',
                                            message: 'Please enter a valid email address!',
                                        },
                                    ]}
                                >
                                    <AutoComplete
                                        options={options}
                                        style={{ width: '100%' }}
                                        onSelect={value => setEmail(value)}
                                        onChange={handleChange}
                                        placeholder="Enter your email"
                                        value={email}
                                        onFocus={() => fetchSuggestions(email)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name="expires" label="Expires" rules={[{ required: true }]}>
                                    <Input name="expires" type='number' placeholder="Expires" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="usage" label="Usage" rules={[{ required: true }]}>
                                    <Input name="usage" type='number' placeholder="Usage" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="intent" label="Intent" rules={[{ required: true }]}>
                                    <Input name="intent" type='text' placeholder="Intent" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="message" label="Message" rules={[{ required: true }]}>
                                    <Input name="message" type='text' placeholder="Message" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="note" label="Note" rules={[{ required: true }]}>
                                    <Input name="note" type='text' placeholder="Note" />
                                </Form.Item>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}

import { Button, Card, message, Modal, Popconfirm, Space } from 'antd';
import React, { useState, useEffect } from 'react';

import OrdersApi from '../../../../apis/order-management/recycle-order/RecycleOrdersApi';
import DeviceChecker from '../DeviceChecker';
import RecycleOrdersApi from '../../../../apis/order-management/recycle-order/RecycleOrdersApi';
import CollectionReview from './CollectionReview';
import { SiteOptions } from '../../../../components/layout/util/common';
import { LiaShippingFastSolid } from 'react-icons/lia';

export default function GroupOptions({ baseUrl, activeOrder, activeOrderId, setKey, services, isPaid, handleCancel }) {
    const [loadingObj, setLoadingObj] = useState({
        resendOrderReceivedMail: false,
        sendOrderReminder: false,
        markAsCompleted: false,
        markAsReturn: false,
        setAsUnPaid: false,
    });

    const [visibleDeviceChecker, setVisibleDeviceChecker] = useState(false);
    const [isModalVisibleCollection, setIsModalVisibleCollection] = useState(false);
    const [selectModel, setSelectModel] = useState(null);
    const [bankHolidays, setBankHolidays] = React.useState([]);
    const tradeBaseUrl = SiteOptions[4].value;

    useEffect(() => {
        const fetchBankHolidays = async () => {
            try {
                const response = await fetch('https://www.gov.uk/bank-holidays.json');
                const data = await response.json();

                const today = new Date();
                const oneYearFromToday = new Date(today);
                oneYearFromToday.setFullYear(today.getFullYear() + 1);

                const englandWalesHolidays = data['england-and-wales'].events
                    .map(({ title, date }) => ({ title, date: new Date(date) }))
                    .filter(({ date }) => {
                        const holidayDate = new Date(date);
                        return holidayDate >= today && holidayDate <= oneYearFromToday;
                    });
                setBankHolidays(englandWalesHolidays);
            } catch (error) {
                console.error('Error fetching bank holidays:', error);
            }
        };

        fetchBankHolidays();
    }, []);


    const { confirm } = Modal;
    const resendOrderReceivedMailHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            resendOrderReceivedMail: true,
        });

        try {
            if (!activeOrderId)
                throw new Error('Order id is required!');

            const response = await OrdersApi.sendResendOrderConfirmation(baseUrl, activeOrderId);
            message.success(response.data);
            setLoadingObj({
                ...loadingObj,
                resendOrderReceivedMail: false
            });
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                resendOrderReceivedMail: false
            });
        }
    }

    const sendOrderReminderHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            sendOrderReminder: true,
        });

        try {
            if (!activeOrderId)
                throw new Error('Order id is required!');

            const response = await OrdersApi.sendOrderReminder(baseUrl, activeOrderId);
            message.success(response.success);
            setLoadingObj({
                ...loadingObj,
                sendOrderReminder: false
            });
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                sendOrderReminder: false
            });
        }
    }

    const markAsCompletedHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            markAsCompleted: true,
        });

        try {
            if (!activeOrderId)
                throw new Error('Order id is required!');

            const response = await OrdersApi.markAsCompleted(baseUrl, activeOrderId);
            message.success(response.success);
            setLoadingObj({
                ...loadingObj,
                markAsCompleted: false
            });
            setKey(prevState => prevState + 1);
            handleCancel();
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                markAsCompleted: false
            });
        }
    }

    const markAsReturnHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            markAsReturn: true,
        });

        try {
            if (!activeOrderId)
                throw new Error('Order id is required!');

            const response = await OrdersApi.markAsReturn(baseUrl, activeOrderId);
            message.success(response.success);
            setLoadingObj({
                ...loadingObj,
                markAsReturn: false
            });
            setKey(prevState => prevState + 1);
            handleCancel();
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                markAsReturn: false
            });
        }
    }

    const setAsUnPaidHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            setAsUnPaid: true,
        });

        try {
            if (!activeOrderId)
                throw new Error('Order id is required!');

            const response = await OrdersApi.setAsUnPaid(baseUrl, activeOrderId);
            message.success(response.success);
            setLoadingObj({
                ...loadingObj,
                setAsUnPaid: false
            });
            setKey(prevState => prevState + 1);
            handleCancel();
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                setAsUnPaid: false
            });
        }
    }

    const showModal = (value) => {
        setSelectModel(value)

        if (value === 'mark') {
            if (activeOrder?.is_collection) {
                try {
                    confirm({
                        title: 'Remove the collection?',
                        content: "Are you sure you want to remove the collection?",
                        okText: 'Yes',
                        cancelText: 'No',
                        async onOk() {
                            try {
                                const response = await RecycleOrdersApi.markRemoveCollectionOrder(baseUrl, activeOrderId);

                                // Ensure the response is valid
                                if (!response || !response.data) {
                                    throw new Error('Invalid response from the API');
                                }

                                message.success(response.data);
                                setKey((prevState) => prevState + 1);
                                handleCancel();
                                setLoadingObj({ ...loadingObj });
                            } catch (error) {
                                // Log and show detailed error message
                                console.error('Error in API call:', error);
                                message.error(
                                    error?.response?.data?.message || 'Failed to remove the collection order. Please try again.'
                                );
                            }
                        },
                        onCancel() {
                            console.log('Cancel');
                        },
                    });
                } catch (error) {
                    // Handle ConfirmModal setup errors
                    console.error('Error in ConfirmModal setup:', error);
                    message.error('An error occurred while setting up the modal.');
                }
            } else {
                setIsModalVisibleCollection(true);
            }
        } else {
            setIsModalVisibleCollection(true);
        }
    };


    return (
        <Card className='mb-4'>
            <Space align="center" size="middle" wrap>
                <Button type="dashed" danger onClick={resendOrderReceivedMailHandler} loading={loadingObj.resendOrderReceivedMail}>Resend Order Confirm Mail</Button>
                <Button type='primary' ghost onClick={sendOrderReminderHandler} loading={loadingObj.sendOrderReminder}>Send Order Reminder</Button>
                {!isPaid &&
                    <Popconfirm
                        title="Are you sure to complete this order?"
                        onConfirm={markAsCompletedHandler}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='primary' danger ghost loading={loadingObj.markAsCompleted}>Mark as Completed</Button>
                    </Popconfirm>
                }
                <Popconfirm
                    title="Are you sure to return this order?"
                    onConfirm={markAsReturnHandler}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type='primary' danger ghost loading={loadingObj.markAsReturn}>Mark as Return</Button>
                </Popconfirm>

                {isPaid &&
                    <Popconfirm
                        title="Are you sure to unpaid this order?"
                        onConfirm={setAsUnPaidHandler}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='primary' danger loading={loadingObj.setAsUnPaid}>Set as Unpaid</Button>
                    </Popconfirm>
                }
                <Button onClick={() => setVisibleDeviceChecker(true)}>Print Label</Button>

                {baseUrl !== tradeBaseUrl && (
                    <>
                        {activeOrder?.is_collection_arranged ? (
                            <Button
                                color="default"
                                variant="solid"
                                onClick={() => showModal('arranged')}
                                icon={<LiaShippingFastSolid />}
                            >
                                Collection Review
                            </Button>
                        ) : (
                            <>
                                {activeOrder?.is_collection === 1 && (
                                    <Button
                                        color="danger"
                                        variant="filled"
                                        onClick={() => showModal('arranged')}
                                        icon={<LiaShippingFastSolid />}
                                    >
                                        Collection arranged
                                    </Button>
                                )}

                                <Button
                                    color={activeOrder?.is_collection ? 'danger' : 'primary'}
                                    variant="outlined"
                                    onClick={() => showModal('mark')}
                                    icon={<LiaShippingFastSolid />}
                                >
                                    {activeOrder?.is_collection ? 'Unmark' : 'Mark'} Collection
                                </Button>
                            </>
                        )}
                    </>
                )}
            </Space>
            {visibleDeviceChecker &&
                <DeviceChecker
                    visible={visibleDeviceChecker}
                    handleCancel={() => setVisibleDeviceChecker(false)}
                    activeOrderId={activeOrderId}
                    setKey={setKey}
                    baseUrl={baseUrl}
                    services={services}
                    maskClosable
                />
            }

            <CollectionReview
                isModalVisibleCollection={isModalVisibleCollection}
                setIsModalVisibleCollection={setIsModalVisibleCollection}
                selectModel={selectModel}
                setSelectModel={setSelectModel}
                activeOrderId={activeOrderId}
                activeOrder={activeOrder}
                baseUrl={baseUrl}
                setKey={setKey}
                handleCancel={handleCancel}
                loadingObj={loadingObj}
                setLoadingObj={setLoadingObj}
                bankHolidays={bankHolidays}
            />

        </Card>
    )
}

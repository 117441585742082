import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./slices/auth";
import messageReducer from "./slices/message";

import shippingOrdersReducer from './slices/shippingOrdersSlice';
import shippingOrdersRecycleReducer from './slices/shippingOrdersRecycleSlice';

import menuBadgeCountKeyReducer from './slices/menuBadgeCountKeySlice';

const persistConfig = {
    key: "root",
    storage,
};

const rootReducer = combineReducers({
    auth: authReducer,
    message: messageReducer,
    menuBadgeCountKey: menuBadgeCountKeyReducer,

    repairOrdersShipping: shippingOrdersReducer,
    recycleOrdersShipping: shippingOrdersRecycleReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.REACT_APP_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
            },
        }),
});

export const persistor = persistStore(store);
import { Button, Col, Form, Input, Row, Space, Switch, message } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux';

import OrdersApi from '../../../../apis/order-management/repair-order/OrdersApi';
import { v4 as uuid } from "uuid";
import { TicketApi } from '../../../../apis/zendesk/TicketApi';
  
export default function VATInvoiceForOrder({ activeOrder }) {
  const baseUrl = useSelector((state) => state.auth.base_url);
  const [form] = Form.useForm();
  
  const [loadingObj, setLoadingObj] = useState({
    previewLoading: false,
    sendLoading: false,
  });
  const [hideTicketId, setHideTicketId] = useState(true);

  const handlePdfPreview = async (isDownload) => {
    setLoadingObj(prevState => ({ ...prevState, previewLoading: true }));
    try {
      const response = await OrdersApi.generateInvoiceByOrderId(baseUrl, activeOrder.id);
      const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
      filePreviewOrDownload(url, isDownload);
      setLoadingObj(prevState => ({ ...prevState, previewLoading: false }));
    } catch (error) {
      message.error('Failed to generate invoice');
      setLoadingObj(prevState => ({ ...prevState, previewLoading: false }));
    }
  }

  const filePreviewOrDownload = (url, isDownload) => {
    const link = document.createElement('a');
    link.href = url;
    if (isDownload)
      link.setAttribute('download', uuid() + '.pdf');
    else
      link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Form
      form={form}
      layout="vertical"
      name="form_in_modal_order_vat_invoice"
      initialValues={{
        new_ticket: false,
      }}
      onValuesChange={(changedValues) => {
        const hideTicketId = !changedValues.new_ticket;
        if (changedValues.new_ticket) {
          form.setFieldsValue({ ticket_id: undefined });
        }
        setHideTicketId(hideTicketId);
      }}
      onFinish={async (values) => {
        try {
          setLoadingObj(prevState => ({ ...prevState, sendLoading: true }));
          const fileResponse = await OrdersApi.generateInvoiceByOrderId(baseUrl, activeOrder.id);
          const file = new Blob([fileResponse], { type: 'application/json' });
          file.name = 'invoice.pdf';
          file.uid = uuid() + '.pdf';

          const { data: { upload: { token } } } = await TicketApi.upload(file);
          if (token) {
            const sendInvoiceRequest = {
              ticket_id: values.ticket_id,
              customer: `${activeOrder.first_name} ${activeOrder.last_name ? activeOrder.last_name : ""}`,
              email: values.new_ticket ? activeOrder.email : null,
              upload_token: token,
            };

            const response = await OrdersApi.sendInvoice(baseUrl, sendInvoiceRequest);
            message.success(response.data);
            form.resetFields();
          }
          setLoadingObj(prevState => ({ ...prevState, sendLoading: false }));
        } catch (error) {
          setLoadingObj(prevState => ({ ...prevState, sendLoading: false }));
          if (error.errorFields)
            message.error(error.errorFields[0].errors[0]);
          else
            message.error('Failed to send invoice');
        }
      }}
    >
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label="Send New Ticket?" name="new_ticket" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>

        <Col span={12}>
          {hideTicketId && (
            <Form.Item
              name="ticket_id"
              label="Ticket Id (Zendesk):"
              rules={[
                {
                  required: true,
                  message: 'Please input ticket id!',
                },
              ]}
            >
              <Input placeholder="Follow-up ticket id" />
            </Form.Item>
          )}
        </Col>

        <Col
          className='mt-2 d-flex justify-between'
          span={24}>
          <Space>
            <Button
              type='link'
              htmlType="button"
              loading={loadingObj.previewLoading}
              onClick={() => {
                form.validateFields()
                  .then(() => {
                    handlePdfPreview(true);
                  })
                  .catch((info) => {
                    message.error(info.errorFields[0].errors[0]);
                  })
              }}
            >
              Download
            </Button>
            <Button
              type="dashed"
              htmlType="button"
              danger
              loading={loadingObj.previewLoading}
              onClick={() => {
                form.validateFields()
                  .then(() => {
                    handlePdfPreview(false);
                  })
                  .catch((info) => {
                    message.error(info.errorFields[0].errors[0]);
                  })
              }}
            >
              Preview
            </Button>
          </Space>

          <Space>
            <Button type="primary" htmlType="submit" loading={loadingObj.sendLoading}>
              Send
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  )
}

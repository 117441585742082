import React from 'react';
import { Tag } from 'antd';
import { LiaShippingFastSolid } from "react-icons/lia";
import { FcShipped } from "react-icons/fc";

export default function StatusBadge({ value, color, label, isCollection = null, isCollectionArranged = null, ignorePayStatusForCollection = false, ...rest }) {
  if (value === null) return "-";

  return (
    <>
      <Tag color={color} {...rest}>{label}</Tag>

      {/* If the order is a collection and payment status is paid, show the shipping icon */}
      {(isCollection === true || isCollection === 1) && (value === 1 || ignorePayStatusForCollection) && (
        !isCollectionArranged ? (
          <LiaShippingFastSolid className='text-danger fs-6' />
        ) : (
          <FcShipped className='fs-6' />
        )
      )}
    </>
  );
}

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Select, message, Drawer, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { useSelector } from "react-redux";
import CustomersApi from '../../../apis/order-management/CustomersApi';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;
const { confirm } = Modal;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickEdit({ visible, handleCancel, activeCustomer, handleReset, roles, setIndex, rolesList }) {
    const validateMessages = {
        required: '${label} is required!',
    };

    const [form] = Form.useForm();

    useEffect(() => {
        if (activeCustomer) {
            form.setFieldsValue({
                name: activeCustomer?.name,
                email: activeCustomer?.email,
                role_id: activeCustomer?.roles.map((role) => role.id) || [],
            });
        }
    }, [activeCustomer, form]);

    const baseUrl = useSelector((state) => state.auth.base_url)
    const [formOptions, setFormOptions] = useState({
        type: [
            { value: 0, label: 'Customer Fault' },
            { value: 1, label: 'Company Fault' },
        ]
    });


    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        try {

            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('email', values.email);
            formData.append('isPromo', values.isPromo);
            formData.append('isRole', values.isRole);
            formData.append('role_id', values.role_id);

            if (activeCustomer) {
                formData.append('id', activeCustomer?.id);
            }

            try {

                const updateCustomer = await CustomersApi.updateCustomer(baseUrl, formData);
                if (updateCustomer) {
                    message.success('Customer is Updated!');
                    setIndex(prevIndex => prevIndex + 1);
                    form.resetFields();
                    handleReset();
                }
            } catch (error) {
                console.log(error);

                message.error('Something went wrong!');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleDeleteCustomer = async () => {

        try {
            const deleteLogs = await CustomersApi.deleteOne(baseUrl, activeCustomer.id);
            if (deleteLogs) {
                message.success('Customer is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form.resetFields();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `Selected Item`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteCustomer();
            },
            onCancel() {
                //
            },
        });
    }


    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }

    return (
        <Drawer
            push={false}
            key="notification-details-model"
            title={[
                <Row key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>Customer Form</Col>
                    <div>
                        <Button key="cancel" onClick={handleCancel}>Cancel</Button>

                        <Button
                            className='mx-2'
                            form='delivery-update'
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {activeCustomer ? "Update" : 'Create'}
                        </Button>
                    </div>
                </Row>
            ]}
            open={visible}
            onClose={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={890}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeCustomer && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>

                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form}
                id="delivery-update"
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
            >
                <Row gutter={[24, 0]}>

                    <Col span={23}>
                        <Row gutter={[24, 0]}>

                            <Col span={12}>
                                <Form.Item name="name" label="User Name" rules={[{ required: true }]}>
                                    <Input name="name" placeholder="Enter Name" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name="email" label="Email" rules={[{ required: true }]}>
                                    <Input name="email" placeholder="Enter Email" />
                                </Form.Item>
                            </Col>

                            {!activeCustomer?.id &&
                                <>
                                    <Col span={12}>
                                        <Form.Item
                                            name="password"
                                            label="Password"
                                            rules={[{ required: true, message: 'Please enter your password' }]}
                                        >
                                            <Input.Password name="password" placeholder="Enter Password" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="confirm_password"
                                            label="Confirm Password"
                                            rules={[
                                                { required: true, message: 'Please confirm your password' },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('The two passwords do not match!'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password name="confirm_password" placeholder="Confirm Password" />
                                        </Form.Item>
                                    </Col>
                                </>
                            }

                            <Col span={12}>
                                <Form.Item
                                    name="role_id"
                                    label="Roles"
                                    rules={[{ required: true, message: 'Please select at least one role' }]}
                                >
                                    <Select
                                        mode="tags"
                                        placeholder="Select or enter roles"
                                        style={{ width: '100%' }}
                                    >
                                        {rolesList.map(role => (
                                            <Select.Option key={role?.id} value={role?.id}>
                                                {role?.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name="isPromo" valuePropName="checked" className='mb-0' initialValue={false}>
                                    <Checkbox>
                                        Generate & notify the promo code
                                    </Checkbox>
                                </Form.Item>

                                <Form.Item name="isRole" valuePropName="checked" className='mb-0' initialValue={true}>
                                    <Checkbox>
                                        Notify to inform the "type of customer" (Customer/Disabled)
                                    </Checkbox>
                                </Form.Item>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}

import { LoadingOutlined, SearchOutlined, SyncOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Flex,
  Col,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
  message,
  Modal
} from "antd";
import dayjs from 'dayjs';
import qs from 'qs';
import React, { useMemo, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import Highlighter from 'react-highlight-words';
import { useSelector } from "react-redux";

import styles from './Orders.module.scss';

import EmployeeApi from '../../apis/EmployeeApi';
import NotificationTemplateApi from "../../apis/order-management/NotificationTemplateApi";
import OrdersApi from "../../apis/order-management/recycle-order/RecycleOrdersApi";
import PayTypeBadgeAlt from '../../components/PayTypeBadgeAlt';
import StatusBadge from '../../components/StatusBadge';
import { SiteOptions } from '../../components/layout/util/common';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useLogin from '../../hooks/useLogin';
import EditableCell from './Components/Editable/EditableCell';
import EditableRow from './Components/Editable/EditableRow';
import ExpandedRowRender from "./Components/ExpandedRowRender";
import QuickEdit from "./Components/QuickEdit";
import SelectedOptions from "./Components/SelectedOptions";
import { filteredOrderStatus, getAllOrderStatus, getAllPayStatus, getAllWFCStatus, getOrderStatusByValue, getPayStatusByValue, getWFCStatusByValue } from "./util/common";
import ServiceApi from '../../apis/order-management/ServiceApi';
import Basket from '../../components/Basket';
import { getBaseUrlByRecycleOrderId } from '../../helpers/getBaseUrlByOrderId';
import TableRowDetails from './Components/TableRowDetails';
import RecycleOrdersApi from '../../apis/order-management/recycle-order/RecycleOrdersApi';

const { Text } = Typography;
const { confirm } = Modal;

const LoadingIcon = <LoadingOutlined style={{ fontSize: '2rem' }} spin />;

export default function Orders(props) {
  useDocumentTitle(props.title);

  const { roles } = useSelector((state) => state.auth.user[0])
  const baseUrl = useSelector((state) => state.auth.base_url)

  const [searchableOrderId, setSearchableOrderId] = useState('');
  const shippingOrders = useSelector((state) => state.shippingOrdersRecycle?.shippingOrdersRecycle);

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [key, setKey] = useState(0);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 100,
    },
    query: "",
    status: null,
    wfc_status: null,
    is_collection: null,
    is_paid: null
  });

  const [notificationTemplate, setNotificationTemplate] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [orderStatusCounts, setOrderStatusCounts] = useState(null);
  const [visibleBulkAction, setVisibleBulkAction] = useState(false);
  const [services, setServices] = useState(null);

  const queryRef = useRef('');
  const [query, setQuery] = useState('');
  const [orderIdSearch, setOrderIdSearch] = useState('');

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef('');

  const [selectedValues, setSelectedValues] = useState({
    selectedRowKeys: [],
    batchRecord: []
  });

  const tradeBaseUrl = SiteOptions[4].value;

  const [countsOrder, setCounts] = useState({
    urgentOrder: 0,
    collectionOrder: 0,
  });

  const [visible, setVisible] = useState(false);
  const [activeOrderId, setActiveOrderId] = useState(null);

  const rowSelection = {
    selectedRowKeys: selectedValues.selectedRowKeys,
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, selectedRows) => setSelectedValues({ selectedRowKeys, batchRecord: selectedRows }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE
    ]
  }

  const { handleChangeLogin } = useLogin(setLoading);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleResetSearch = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleResetSearch(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => { if (record[dataIndex]) return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) },
    filterDropdownProps: {
      onOpenChange: (open) => {
        if (open) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const defaultColumns = [
    {
      title: "Order #",
      dataIndex: "order_no",
      key: "order_no",
      render: (text, record) => (
        <TableRowDetails
          id={record.id}
          baseUrl={baseUrl}
        >
          {text}
        </TableRowDetails>
      ),
      sorter: (a, b) => a.order_no > b.order_no,
      onFilter: (value, { order_no }) => (value === order_no),
    },
    {
      title: "Created At",
      key: "created_at",
      dataIndex: "created_at",
      render: text => (text ? <Text type="secondary" title={dayjs(text).format('MMMM Do YYYY, h:mm:ss a')}>{dayjs(text).format("DD-MM-YYYY")}</Text> : "-"),
      sorter: (a, b) => dayjs(a.created_at) - dayjs(b.created_at),
    },
    {
      title: "Full Name",
      key: "full_name",
      dataIndex: "full_name",
      ...getColumnSearchProps('full_name'),
      width: 300,
      onFilter: (value, { user }) => {
        let fullName = `${user?.first_name} ${user?.last_name ? user?.last_name : ""}`;
        if (fullName) return fullName.toString().toLowerCase().includes(value.toLowerCase())
      },
      filterDropdownProps: {
        onOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        },
      },
      render: (_, { user }) => {
        let fullName = `${user?.first_name ?? user?.name} ${user?.last_name ? user?.last_name : ""}`;
        return (searchedColumn === 'full_name' ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={fullName ? fullName.toString() : ''}
          />
        ) : (
          fullName
        ))
      },
    },
    {
      title: "Postcode",
      key: "postal_code",
      dataIndex: "postal_code",
      ...getColumnSearchProps('postal_code'),
      onFilter: (value, { user }) => {
        return user?.postal_code.toString().toLowerCase().includes(value.toLowerCase())
      },
      filterDropdownProps: {
        onOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        },
      },
      render: (_, { user }) => <Text>{(user?.postal_code ?? (user?.company?.postal_code ?? ""))}</Text>,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (value) => {
        const statusObj = getOrderStatusByValue(value);
        return (<StatusBadge value={value} color={statusObj?.status} label={statusObj?.label} />);
      },
      filters: (getAllOrderStatus.slice(1)).map((item) => ({ text: item.label, value: item.value })),
      onFilter: (value, record) => (value === record.status),
      editable: true,
    },
    {
      title: "WFC",
      key: "wfc_status",
      dataIndex: "wfc_status",
      render: (value) => {
        const statusObj = getWFCStatusByValue(value);
        return (<StatusBadge value={value} color={statusObj?.status} label={statusObj?.label} />);
      },
      filters: (getAllWFCStatus.slice(1)).map((item) => ({ text: item.label, value: item.value })),
      onFilter: (value, record) => (value === record.wfc_status),
      editable: true,
    },
    {
      title: "Pay Status",
      key: "is_paid",
      dataIndex: "is_paid",
      render: (value, record) => {
        const statusObj = getPayStatusByValue(value);
        return (
          <StatusBadge
            value={value}
            color={statusObj?.color}
            label={statusObj?.label}
            isCollection={record?.is_collection}
            isCollectionArranged={record?.is_collection && record?.is_collection_arranged}
            ignorePayStatusForCollection
          />
        );
      },
      filters: getAllPayStatus.map((item) => ({ text: item.label, value: item.value })),
      onFilter: (value, record) => (value === record.is_paid),
    },
    {
      title: "Pay Type",
      key: "payment_type",
      dataIndex: "payment_type",
      render: (_, { payment_details }) => {
        let paymentType = 'paypal';
        if (payment_details?.data) {
          const payment = JSON.parse(payment_details.data);
          paymentType = payment?.sort_code ? 'bank' : 'paypal';
        } else if (!payment_details?.paypal_username) {
          paymentType = 'bank';
        }
        return <PayTypeBadgeAlt type={paymentType} />;
      },
    }
  ];

  const onChange = (e) => {
    // reset the orderIdSearch, query
    handleResetFilter(true);

    setTableParams(prevTableParams => ({
      ...prevTableParams,
      pagination: { ...prevTableParams.pagination },
      query: "",
      status: null,
      wfc_status: null,
      [e.target.name]: e.target.value
    }));
  };

  const changeBaseUrlToFMBS = async () => {
    const tradeBaseUrl = SiteOptions[2].value;
    if (baseUrl !== tradeBaseUrl) return;
    await handleBaseUrlChange(tradeBaseUrl);
  }

  useLayoutEffect(() => {
    let isCancelled = false
    changeBaseUrlToFMBS(); // change to fmbs baseurl
    mountData(isCancelled);

    return () => {
      isCancelled = true
    }
  }, []);

  useLayoutEffect(() => {
    let isCancelled = false
    fetchCounts(isCancelled);

    return () => {
      isCancelled = true
    }
  }, [key]);

  const getQueryParams = (params) => ({
    current_page: params.pagination?.current,
    per_page: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });

  const fetchOrders = useCallback((isCancelled) => {
    setLoading(true);
    OrdersApi.list(baseUrl, qs.stringify(getQueryParams(tableParams)))
      .then(({ data: orderData }) => {
        if (!isCancelled) {
          setOrders(orderData.data);
          setTableParams((prevTableParams) => ({
            ...prevTableParams,
            query: getValidSearchText(tableParams.query, searchableOrderId),
            pagination: {
              ...prevTableParams.pagination,
              current: orderData.current_page,
              pageSize: orderData.per_page,
              total: orderData.total,
            },
          }));
          setLoading(false);
        }
      })
      .catch(err => {
        if (!isCancelled)
          setLoading(false);
      });
  }, [baseUrl, tableParams, searchableOrderId, getQueryParams]);

  function getValidSearchText(query, searchText) {
    const searchBoxText = queryRef.current?.input?.value;
    if (searchText === "") {
      if (searchBoxText === "") return "";
      return query;
    }
    return searchText;
  }

  useEffect(() => {
    let isCancelled = false
    fetchOrders(isCancelled);

    return () => {
      isCancelled = true
    }
  }, [key, JSON.stringify(tableParams), searchableOrderId, baseUrl]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setOrders([]);
    }
  };

  const showModal = (id) => {
    setActiveOrderId(id);
    setVisible(true);
  };

  const handleReset = () => {
    setActiveOrderId(null);
    setVisible(false);
    setVisibleBulkAction(false);
    setKey(prevState => prevState + 1);
  }

  const handleCancel = () => {
    setVisible(false);
    setActiveOrderId(null);
  };

  const handleHide = () => {
    setVisible(false);
    setVisibleBulkAction(true);
  };

  const search = value => {
    setTableParams(prevTableParams => ({
      ...prevTableParams,
      pagination: { ...prevTableParams.pagination },
      status: null,
      wfc_status: null,
      is_collection: value !== "" ? null : true,
      query: value
    }));
  };

  const rowsUnCheckHandler = () => {
    setSelectedValues({
      selectedRowKeys: [],
      batchRecord: []
    })
  }

  const mountData = async (isCancelled) => {
    setTableParams(prevTableParams => ({
      ...prevTableParams,
      is_collection: null,
    }));

    const { data: list } = await NotificationTemplateApi.getAll(baseUrl, 'recycle');
    const employeeList = await EmployeeApi.list(baseUrl);
    const serviceList = await ServiceApi.getServices(baseUrl);
    if (!isCancelled) {
      if (list) setNotificationTemplate(list);
      if (employeeList) setEmployees(employeeList);
      if (serviceList) setServices(serviceList);
    }
  }

  const fetchCounts = async (isCancelled) => {
    const statusCounts = await RecycleOrdersApi.getStatusCounts(baseUrl, qs.stringify(getQueryParams(tableParams)))
    if (!isCancelled) {
      if (statusCounts) {
        setOrderStatusCounts(statusCounts);
      }
    }

    const { data: orderCount } = await RecycleOrdersApi.ordersCount(baseUrl, qs.stringify(getQueryParams(tableParams)))
    if (!isCancelled) {
      if (orderCount) {
        setCounts({
          collectionOrder: orderCount?.total_sent_collection || 0,
        });
      }
    }
  }

  // Table - Editable Cell
  const handleSaveOrderStatus = async ({ id, status, order_no }) => {
    setLoading(true);
    try {
      if (!id)
        throw new Error('Something went wrong!');

      const updatedOrder = await OrdersApi.updateOne(baseUrl, id, {
        status: status,
        is_active: 1,
        order_type: 'recycle'
      });

      if (updatedOrder) {
        message.success(`${order_no} - Order is updated!`);
        setKey(prevState => prevState + 1);
        setLoading(false);
      }
    } catch (e) {
      message.error(e.message ?? 'Something went wrong!');
      setLoading(false);
    }
  }

  const handleSaveWFCStatus = async ({ id, wfc_status, order_no }) => {
    setLoading(true);

    let sendNotificationForWFCPending = false;
    const confirmSendNotification = () => {
      return new Promise((resolve) => {
        confirm({
          title: 'Do you want to send a sms notification to the customer?',
          content: 'This will send a sms notification to the customer.',
          icon: <ExclamationCircleOutlined />,
          okText: 'Yes',
          cancelText: 'No',
          onOk() {
            resolve(true);
          },
          onCancel() {
            resolve(false);
          },
        });
      });
    };

    try {

      // wait for the user to confirm the action for send sms notification when wfc_status is set to '2'
      if (wfc_status == 2) {
        sendNotificationForWFCPending = await confirmSendNotification();
      }

      const formData = new FormData();
      formData.append('wfc_status', wfc_status);
      formData.append('type', 'recycle');
      formData.append('send_notification', sendNotificationForWFCPending);
      const wfcUpdated = await NotificationTemplateApi.wfcUpdate(baseUrl, id, formData);

      message.success(`${order_no} - ${wfcUpdated.message}!`);
      setKey(prevState => prevState + 1);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return {
        ...col,
        onCell: (record) => ({
          onClick: (_) => {
            showModal(record.id)
          }
        })
      };
    }

    if (col.dataIndex === 'wfc_status') {
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSaveWFCStatus,
        }),
      };
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSaveOrderStatus,
      }),
    };
  });

  const handleBaseUrlChange = async (value) => {
    // Reset the filters completely
    await handleResetFilter(true);

    const result = await handleChangeLogin(value);
    if (result.success) {
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
      setKey(prevKey => prevKey + 1)
    }
    else {
      message.success({
        content: result.message,
        transitionName: 'move-down',
      });
    }
  };
  const onSearchHandler = async (orderId) => {
    setTableParams(prevTableParams => ({
      ...prevTableParams,
      status: null,
      wfc_status: null,
      query: "",
      is_collection: null,
    }));

    if (orderId === "") {
      setSearchableOrderId("");
      return;
    }

    const nextBaseUrl = await getBaseUrlByRecycleOrderId(baseUrl, orderId)
    if (nextBaseUrl === baseUrl) {
      setSearchableOrderId(orderId);
      return;
    }
    await handleBaseUrlChange(nextBaseUrl);
    setSearchableOrderId(orderId);
  }

  const handleResetFilter = (isCompleteReset = false) => {
    // to clear the search input of query
    if (query !== "") setQuery('');

    // to clear the search input of orderIdSearch
    if (orderIdSearch !== "") setOrderIdSearch('');

    // to re-render the orderId search
    if (searchableOrderId !== "") setSearchableOrderId("");

    // to re-render the query search
    if (tableParams.query !== "" | tableParams.status !== null | tableParams.wfc_status !== null | tableParams.is_collection !== null)
      setTableParams(prevTableParams => ({
        ...prevTableParams,
        query: "",
        is_collection: null,
        status: isCompleteReset ? null : prevTableParams.status,
        wfc_status: isCompleteReset ? null : prevTableParams.wfc_status,
      }));
  }

  const onChangeOrders = (e) => {
    const selectedValue = e.target.value;
    // to clear the search input of query
    if (query !== "") setQuery('');

    // to clear the search input of orderIdSearch
    if (orderIdSearch !== "") setOrderIdSearch('');


    // to re-render the orderId search
    if (searchableOrderId !== "") setSearchableOrderId("");

    setTableParams(prevTableParams => ({
      ...prevTableParams,
      pagination: { ...prevTableParams.pagination },
      query: "",
      status: null,
      wfc_status: null,
      is_collection: selectedValue === 'collectionOrders' ? true : null
    }));
  };

  const optionsOrder = useMemo(() => [
    {
      label: (
        <span>All</span>
      ),
      value: 'all',
    },
    {
      label: (
        <Flex>
          <span>Collection Orders</span>
          {countsOrder?.collectionOrder > 0 && (
            <Badge
              count={countsOrder?.collectionOrder}
              style={{
                marginLeft: '6px',
              }}
            />
          )}
        </Flex>
      ),
      value: 'collectionOrders',
    },
  ], [countsOrder]);

  return (
    <div className="bg-white py-4 px-4 rounded">
      <Row justify="space-between" align="top">
        <Col md={19} className='d-inline-flex'>
          <Button
            icon={<SyncOutlined />}
            loading={loading}
            onClick={() => {
              setKey(prevKey => prevKey + 1)
              setTableParams((prevTableParams) => ({
                ...prevTableParams,
                pagination: {
                  ...prevTableParams.pagination,
                  current: 1,
                },
              }));
            }}
            className="mr-2 px-2"
          />

          <Select
            value={baseUrl}
            onChange={handleBaseUrlChange}
            className="mr-4"
            style={{ width: 260 }}
            options={SiteOptions.map(option => ({
              ...option
            }))}
            disabled={loading}
          />

          <Input.Search
            placeholder="Find by any order id.."
            prefix={<SearchOutlined />}
            allowClear
            onSearch={onSearchHandler}
            className={`${styles.search} mr-4`}
            disabled={loading}
            style={{ width: 320 }}
            onChange={(e) => setOrderIdSearch(e.target.value)}
            value={orderIdSearch}
          />

          <Input.Search
            ref={queryRef}
            placeholder="Search by Order Id, Created At, Name, E-Mail, Ph. No, Postcode"
            className={`${styles.search}`}
            onSearch={search}
            allowClear
            disabled={loading}
            style={{ width: 500 }}
            onChange={(e) => setQuery(e.target.value)}
            value={query}
          />
        </Col>
        {baseUrl !== tradeBaseUrl &&
          <Col md={5}>
            <Flex
              style={{
                whiteSpace: 'nowrap',
              }}
              className='d-flex'
            >
              <Radio.Group
                size='small'
                onChange={onChangeOrders}
                value={(tableParams.is_collection) ? 'collectionOrders' : 'all'}
                block
                options={optionsOrder}
                defaultValue={'all'}
                optionType="button"
                buttonStyle="solid"
              />
            </Flex>
          </Col>
        }
      </Row>

      <Row className="mt-4" justify="space-between" align="top">
        <Col md={10} className='d-inline-flex'>
          <Radio.Group
            size='small'
            onChange={onChange}
            defaultValue="null"
            value={tableParams.status}
            name='status'
            id='status'
            disabled={loading}
          >
            {filteredOrderStatus.map((item, idx) => (
              <Radio.Button key={idx} value={item.value ?? ''}>
                <Badge
                  color={item.color}
                  text={item.label + ` ${orderStatusCounts?.statusCounts?.[item.value] !== undefined ? "(" + orderStatusCounts.statusCounts[item.value] + ")" : ""}`}
                  className={styles.font_weight_normal}
                />
              </Radio.Button>
            ))}
          </Radio.Group>
        </Col>

        <Col md={14} className="text-right d-flex mb-4">
          <Flex vertical align='center' justify='end'>
            <Radio.Group
              size='small'
              onChange={onChange}
              defaultValue="null"
              value={tableParams.wfc_status}
              name='wfc_status'
              id='wfc_status'
              className="float-right"
              disabled={loading}
            >
              {getAllWFCStatus.map((item, idx) => (
                <Radio.Button key={idx} value={item.value ?? ''}>
                  <Badge
                    color={item.color}
                    text={item.label + ` ${orderStatusCounts?.wfcStatusCounts?.[item.value] !== undefined ? "(" + orderStatusCounts.wfcStatusCounts[item.value] + ")" : ""}`}
                    className={styles.font_weight_normal}
                  />
                </Radio.Button>
              ))}
            </Radio.Group>
          </Flex>
        </Col>
      </Row>

      <div className="table-responsive pt-4">
        <SelectedOptions
          selectedRowKeys={selectedValues.selectedRowKeys}
          selectedRows={selectedValues.batchRecord}
          setIndex={setKey}
          rowsUnCheckHandler={rowsUnCheckHandler}
          baseUrl={baseUrl}
        />

        <Table
          components={components}
          columns={columns}
          rowKey="id"
          rowSelection={rowSelection}
          dataSource={orders}
          loading={{ spinning: loading, indicator: <Spin size="middle" indicator={LoadingIcon} /> }}
          pagination={{
            showSizeChanger: true,
            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
            showQuickJumper: true,
            pageSizeOptions: [100, 200, 300, 400, 500],
            defaultPageSize: tableParams.pagination.pageSize,
            position: ["bottomRight"],
            ...tableParams.pagination
          }}
          className="ant-border-space"
          rowClassName={() => 'editable-row'}
          size="small"
          ellipsis
          responsive
          expandable={{
            expandedRowRender: (record) => <ExpandedRowRender record={record} />,
            rowExpandable: record => record.order_no !== 'Not Expandable',
          }}
          onChange={handleTableChange}
          scroll={{ y: 600 }}
        />
      </div>

      {visible &&
        <QuickEdit
          visible={visible}
          handleHide={handleHide}
          handleCancel={handleCancel}
          activeOrderId={activeOrderId}
          handleReset={handleReset}
          setKey={setKey}
          notificationTemplate={notificationTemplate}
          baseUrl={baseUrl}
          roles={roles}
          employees={employees}
          services={services}
        />
      }

      <Basket
        key='1'
        orders={orders}
        setKey={setKey}
        baseUrl={baseUrl}
        visible={visibleBulkAction}
        setVisible={setVisibleBulkAction}
        ordersType="recycle"
      />
    </div>
  );
}
import { createSlice } from '@reduxjs/toolkit';

import { SiteOptions } from '../../components/layout/util/common';

const initialState = {
    shipping: SiteOptions.reduce((acc, site) => {
        acc[site.brand] = []; // Initialize each site with an empty array
        return acc;
    }, {}),
};

const shippingOrdersRecycleSlice = createSlice({
    name: 'recycleOrdersShipping',
    initialState,
    reducers: {
        addSiteShippingOrder: (state, action) => {
            const { site, order } = action.payload;
            state.shipping[site].push(order);
        },
        deleteSiteShippingOrder: (state, action) => {
            const { site, orderId } = action.payload;
            state.shipping[site] = state.shipping[site].filter(order => order.id !== orderId);
        },
        deleteSiteShippingOrderWithOrderIds: (state, action) => {
            const { site, orderId } = action.payload;
            state.shipping[site] = state.shipping[site].filter(order => !orderId.includes(order.id));
        },
        clearSiteShippingOrders: (state, action) => {
            const { site } = action.payload;
            state.shipping[site] = [];
        },
    },
});

export const {
    addSiteShippingOrder,
    deleteSiteShippingOrder,
    deleteSiteShippingOrderWithOrderIds,
    clearSiteShippingOrders,
} = shippingOrdersRecycleSlice.actions;

export default shippingOrdersRecycleSlice.reducer;


import { Button, Card, message, Space, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { LiaShippingFastSolid } from 'react-icons/lia';

import OrdersApi from '../../../../apis/order-management/repair-order/OrdersApi';
import DeviceChecker from '../DeviceChecker';
import useSideMenuBadgeCount from '../../../../hooks/useSideMenuBadgeCount';
import CollectionReview from './CollectionReview';
import { SiteOptions } from '../../../../components/layout/util/common';

const { confirm } = Modal;

export default function GroupOptions({ activeOrder, baseUrl, activeOrderId, services, setKey, handleCancel, visibleDeviceChecker, setVisibleDeviceChecker }) {
    const [loadingObj, setLoadingObj] = useState({
        printLabel: false,
        resendOrderReceivedMail: false,
        exportDelivery: false,
        unpaidMail: false,
        deviceCheck: false,
        urgent: false,
        collection: false,
    });
    const [isModalVisibleCollection, setIsModalVisibleCollection] = useState(false);
    const [selectModel, setSelectModel] = useState(null);
    const [bankHolidays, setBankHolidays] = React.useState([]);

    const { mutateCountKey } = useSideMenuBadgeCount();

    const tradeBaseUrl = SiteOptions[4].value;

    useEffect(() => {
        const fetchBankHolidays = async () => {
            try {
                const response = await fetch('https://www.gov.uk/bank-holidays.json');
                const data = await response.json();

                const today = new Date();
                const oneYearFromToday = new Date(today);
                oneYearFromToday.setFullYear(today.getFullYear() + 1);

                const englandWalesHolidays = data['england-and-wales'].events
                    .map(({ title, date }) => ({ title, date: new Date(date) }))
                    .filter(({ date }) => {
                        const holidayDate = new Date(date);
                        return holidayDate >= today && holidayDate <= oneYearFromToday;
                    });
                setBankHolidays(englandWalesHolidays);
            } catch (error) {
                console.error('Error fetching bank holidays:', error);
            }
        };

        fetchBankHolidays();
    }, []);

    const checkoutFailedHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            unpaidMail: true
        });

        try {
            if (!activeOrderId)
                throw new Error('Something went wrong!');

            const response = await OrdersApi.sendUnpaidMail(baseUrl, activeOrderId);
            message.success(response.data);
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
        } finally {
            setLoadingObj({
                ...loadingObj,
                unpaidMail: false
            });
        }
    }

    const resendOrderReceivedMailHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            resendOrderReceivedMail: true
        });

        try {
            if (!activeOrderId)
                throw new Error('Something went wrong!');

            const response = await OrdersApi.sendResendOrderConfirmation(baseUrl, activeOrderId);
            message.success(response.data);
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
        } finally {
            setLoadingObj({
                ...loadingObj,
                resendOrderReceivedMail: false
            });
        }
    }

    const exportDeliveryHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            exportDelivery: true
        });

        try {
            if (!activeOrderId)
                throw new Error('Something went wrong!');

            const response = await OrdersApi.exportDelivery(baseUrl, activeOrderId);
            message.success(response.data);
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
        } finally {
            setLoadingObj({
                ...loadingObj,
                exportDelivery: false
            });
        }
    }

    const urgentHandler = async (value) => {
        setLoadingObj({
            ...loadingObj,
            urgent: true
        });

        try {
            if (!activeOrderId)
                throw new Error('Something went wrong!');

            const response = await OrdersApi.urgentOrder(baseUrl, activeOrderId, value);
            message.success(response.data);
            setKey(prevState => prevState + 1);

            // set update the count key of side menu
            mutateCountKey();
            handleCancel();
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
        } finally {
            setLoadingObj({
                ...loadingObj,
                urgent: false
            });
        }
    }

    const showModal = (value) => {
        setSelectModel(value);

        if (value === 'mark') {
            if (activeOrder?.is_collection) {
                try {
                    confirm({
                        title: 'Remove the collection?',
                        content: "Are you sure you want to remove the collection?",
                        okText: 'Yes',
                        cancelText: 'No',
                        async onOk() {
                            try {
                                const response = await OrdersApi.markRemoveCollectionOrder(baseUrl, activeOrderId);

                                // Ensure the response is valid
                                if (!response || !response.data) {
                                    throw new Error('Invalid response from the API');
                                }

                                message.success(response.data);
                                setKey((prevState) => prevState + 1);
                                mutateCountKey(); // Update the count key of the side menu
                                handleCancel();
                            } catch (error) {
                                // Log and show detailed error message
                                console.error('Error in API call:', error);
                                message.error(
                                    error?.response?.data?.message || 'Failed to remove the collection order. Please try again.'
                                );
                            }
                        },
                        onCancel() {
                            console.log('Cancel');
                        },
                    });
                } catch (error) {
                    // Handle ConfirmModal setup errors
                    console.error('Error in ConfirmModal setup:', error);
                    message.error('An error occurred while setting up the modal.');
                }
            } else {
                setIsModalVisibleCollection(true);
            }
        } else {
            setIsModalVisibleCollection(true);
        }
    }

    return (
        <Card className='mb-4'>
            <Space align="center" size="middle" wrap>
                {activeOrder.is_paid === 0 &&
                    <Button danger onClick={checkoutFailedHandler} loading={loadingObj.unpaidMail} >Checkout Failed</Button>
                }

                {activeOrder.is_paid === 1 &&
                    <>
                        <Button type="dashed" danger onClick={resendOrderReceivedMailHandler} loading={loadingObj.resendOrderReceivedMail}>Resend Order Confirm Mail</Button>
                        <Button type="dashed" onClick={exportDeliveryHandler} loading={loadingObj.exportDelivery}>Ex. Delivery</Button>
                    </>
                }

                <Button onClick={() => setVisibleDeviceChecker(true)}>Print Label</Button>

                <Button danger onClick={() => urgentHandler(activeOrder?.is_urgent === 0)} loading={loadingObj.urgent}>{activeOrder?.is_urgent === 0 ? `Mark` : `Un-Mark`} Urgent</Button>

                {baseUrl !== tradeBaseUrl && (
                    <>
                        {activeOrder?.is_collection_arranged ? (
                            <Button
                                color="default"
                                variant="solid"
                                onClick={() => showModal('arranged')}
                                icon={<LiaShippingFastSolid />}
                            >
                                Collection Review
                            </Button>
                        ) : (
                            <>
                                {activeOrder?.is_collection === 1 && (
                                    <Button
                                        color="danger"
                                        variant="filled"
                                        onClick={() => showModal('arranged')}
                                        icon={<LiaShippingFastSolid />}
                                    >
                                        Collection arranged
                                    </Button>
                                )}

                                <Button
                                    color={activeOrder?.is_collection ? 'danger' : 'primary'}
                                    variant="outlined"
                                    onClick={() => showModal('mark')}
                                    icon={<LiaShippingFastSolid />}
                                >
                                    {activeOrder?.is_collection ? 'Unmark' : 'Mark'} Collection
                                </Button>
                            </>
                        )}
                    </>
                )}
            </Space>

            {visibleDeviceChecker &&
                <DeviceChecker
                    visible={visibleDeviceChecker}
                    handleCancel={() => setVisibleDeviceChecker(false)}
                    activeOrderId={activeOrderId}
                    setKey={setKey}
                    baseUrl={baseUrl}
                    services={services}
                    maskClosable
                />
            }

            <CollectionReview
                isModalVisibleCollection={isModalVisibleCollection}
                setIsModalVisibleCollection={setIsModalVisibleCollection}
                selectModel={selectModel}
                setSelectModel={setSelectModel}
                activeOrderId={activeOrderId}
                activeOrder={activeOrder}
                baseUrl={baseUrl}
                setKey={setKey}
                handleCancel={handleCancel}
                loadingObj={loadingObj}
                setLoadingObj={setLoadingObj}
                mutateCountKey={mutateCountKey}
                bankHolidays={bankHolidays}
            />
        </Card>
    )
}

import { LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import {
  Card,
  Col,
  Row,
  Spin,
  Table,
  Button,
  Form,
  Input,
  Typography,
} from "antd";
import qs from 'qs';
import React, { useState, useEffect, useCallback, useRef } from "react";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.locale('en-gb');

import styles from './Customers.module.scss';

import { useSelector } from 'react-redux';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import QuickEdit from "./Components/QuickEdit";
import SelectedOptions from "./Components/SelectedOptions";
import useLogin from '../../hooks/useLogin';
import CustomersApi from '../../apis/order-management/CustomersApi';

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)
dayjs.tz.setDefault('Europe/London');

const LoadingIcon = <LoadingOutlined style={{ fontSize: '2rem' }} spin />;
const { Text } = Typography;


export default function Customers(props) {

  useDocumentTitle(props.title);
  const { roles } = useSelector((state) => state.auth.user[0])
  const baseUrl = useSelector((state) => state.auth.base_url)
  const [form] = Form.useForm();
  const [activeButton, setActiveButton] = useState('all');

  const [tableTab, setTableTab] = useState(0);
  const [Customer, setCustomer] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(0);
  const [filterCustomer, setfilterCustomer] = useState(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 100,
    },
    query: "",
    status: null,
    wfc_status: null,
    userType: activeButton,
    is_paid: null
  });

  const queryRef = useRef('');

  const [selectedValues, setSelectedValues] = useState({
    selectedRowKeys: [],
    batchRecord: []
  });

  const getQueryParams = (params) => ({
    current_page: params.pagination?.current,
    per_page: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });

  const [visible, setVisible] = useState(false);
  const [activeCustomer, setActiveCustomer] = useState(null);

  const rowSelection = {
    selectedRowKeys: selectedValues.selectedRowKeys,
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, selectedRows) => setSelectedValues({ selectedRowKeys, batchRecord: selectedRows }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE
    ]
  }


  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Roles",
      key: "roles",
      dataIndex: "roles",
      render: (roles) => {
        // Check if roles exist and loop through them to display the display_name
        if (roles && roles.length > 0) {
          return roles.map((role) => role.display_name).join(", ");
        } else {
          return "No roles assigned";
        }
      }
    },

    {
      title: "Created At",
      key: "created_at",
      dataIndex: "created_at",
      render: text => (text ? <Text type="secondary" title={dayjs(text).format('MMMM Do YYYY, h:mm:ss a')}>{dayjs(text).format("DD-MM-YYYY")}</Text> : "-"),
    },
  ];

  const fetchCustomer = useCallback((isCancelled) => {
    setLoading(true);
    CustomersApi.list(baseUrl, qs.stringify(getQueryParams(tableParams)))
      .then(({ data: customerData }) => {

        if (!isCancelled) {
          setCustomer(customerData.data);
          setTableParams((prevTableParams) => ({
            ...prevTableParams,
            query: getValidSearchText(tableParams.query),
            pagination: {
              ...prevTableParams.pagination,
              current: customerData.current_page,
              pageSize: customerData.per_page,
              total: customerData.total,
            },
          }));
          setLoading(false);
        }
      })

    CustomersApi.listRoles(baseUrl)
      .then(({ data: rolesData }) => {
        
        if (!isCancelled) {
          setRolesList(rolesData);
          setLoading(false);
        }
      })
      .catch(err => {
        if (!isCancelled)
          setLoading(false);
      });
  }, [baseUrl, tableParams, getQueryParams]);

  function getValidSearchText(query, searchText) {
    const searchBoxText = queryRef.current?.input?.value;
    if (searchText === "") {
      if (searchBoxText === "") return "";
      return query;
    }
    return searchText;
  }

  useEffect(() => {
    let isCancelled = false
    fetchCustomer(isCancelled);

    return () => {
      isCancelled = true
    }
  }, [key, JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setCustomer([]);
    }
  };

  const showModal = (item) => {
    setVisible(true);
    setActiveCustomer(item);
  };

  const handleReset = () => {
    setActiveCustomer(null);
    setVisible(false);
    setKey(prevState => prevState + 1);
  }

  const handleCancel = () => {
    setVisible(false);
    setActiveCustomer(null);
  };


  const rowsUnCheckHandler = () => {
    setSelectedValues({
      selectedRowKeys: [],
      batchRecord: []
    })
  }


  const { handleChangeLogin } = useLogin(setLoading);


  const pageLoading = () => {
    setTableTab(0);
    setKey(prevKey => prevKey + 1)
    setfilterCustomer(null);
    setActiveButton('all');
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      userType: 'all',
    }));
    form.setFieldsValue({
      filterTable: null,
    });
  };


  const search = value => {

    const filterTable = Customer.filter(o =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );

    setfilterCustomer(filterTable);
  };

  const handleButtonClick = (key) => {
    setActiveButton(key);
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      userType: key,
    }));
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <div className="site-page-header-ghost-wrapper">

              <Card
                bordered={false}
                className={`criclebox tablespace ${styles.table}`}


                styles={{
                  header: {
                    padding: 0,
                    border: 'none'
                  }
                }}
              >
                <Row justify="space-between" align="top">
                  <Col md={19} className='d-inline-flex mb-4'>
                    <Button
                      icon={<SyncOutlined />}
                      loading={loading}
                      onClick={pageLoading}
                      className="mr-2 px-2"
                    />
                    <Form
                      form={form}
                    >
                      <Form.Item name="filterTable"  >
                        <Input.Search
                          ref={queryRef}
                          placeholder="Search by Name, Email"
                          className={`w-100 ${styles.search}`}
                          onSearch={search}
                          allowClear
                          disabled={loading}
                        />
                      </Form.Item>

                    </Form>
                  </Col>

                  <Col md={5} className='d-inline-flex mb-4'>
                    <Button.Group>
                      <Button
                        key="2"
                        type={activeButton === 'disabled' ? 'primary' : 'default'}
                        onClick={() => handleButtonClick('disabled')}
                      >
                        Disabled
                      </Button>

                      <Button
                        key="3"
                        type={activeButton === 'customer' ? 'primary' : 'default'}
                        onClick={() => handleButtonClick('customer')}
                      >
                        Customer
                      </Button>
                      <Button
                        key="1"
                        type={activeButton === 'all' ? 'primary' : 'default'}
                        onClick={() => handleButtonClick('all')}
                      >
                        All
                      </Button>
                    </Button.Group>

                    <Button
                      className='mx-2'
                      form='reason-create'
                      type="primary"
                      onClick={() => showModal(null)}
                      loading={loading}
                    >
                      Create Customer
                    </Button>

                  </Col>

                </Row>
                <div className="table-responsive">

                  <SelectedOptions
                    selectedRowKeys={selectedValues.selectedRowKeys}
                    selectedRows={selectedValues.batchRecord}
                    setIndex={setKey}
                    rowsUnCheckHandler={rowsUnCheckHandler}
                  />

                  <Table
                    columns={columns}
                    rowKey={(record) => record.id}
                    rowSelection={rowSelection}
                    dataSource={filterCustomer === null ? Customer : filterCustomer}
                    loading={{ spinning: loading, indicator: <Spin size="middle" indicator={LoadingIcon} /> }}
                    pagination={{
                      showSizeChanger: true,
                      showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
                      showQuickJumper: true,
                      pageSizeOptions: [100, 200, 300, 400, 500],
                      defaultPageSize: tableParams.pagination.pageSize,
                      position: ["bottomRight"],
                      ...tableParams.pagination
                    }}
                    className="ant-border-space"
                    size="small"
                    ellipsis
                    responsive
                    onChange={handleTableChange}
                    onRow={(record) => {
                      return {
                        onClick: (_) => {
                          showModal(record)
                        }
                      };
                    }}
                  />
                </div>
              </Card>
            </div>
          </Col>
        </Row>

        {visible &&
          <QuickEdit
            visible={visible}
            handleCancel={handleCancel}
            activeCustomer={activeCustomer}
            handleReset={handleReset}
            roles={roles}
            rolesList={rolesList}
            setIndex={setKey}
          />
        }
      </div>
    </>
  );
}
import React, { useState, useMemo } from "react";
import { Popover, Spin, Typography, Flex } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import OrderItems from "./QuickEdit/OrderItems";
import { getOrderStatusByValue } from "../util/common";
import StatusBadge from "../../../components/StatusBadge";
import RecycleOrdersApi from "../../../apis/order-management/recycle-order/RecycleOrdersApi";

const { Text } = Typography;

export default function TableRowDetails({ id, children, baseUrl, ...rest }) {
  const [isLoading, setIsLoading] = useState(false);
  const [activeOrder, setActiveOrder] = useState({});
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const fetchOrder = async () => {
    setIsLoading(true);
    try {
      const order = await RecycleOrdersApi.getOneWithOrderRepair(baseUrl, id);
      setActiveOrder(order);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenChange = (visible) => {
    setIsPopoverOpen(visible);
    if (visible && !activeOrder.id) {
      fetchOrder();
    }
  };

  const total = useMemo(() => activeOrder?.total_price ? parseFloat(activeOrder.total_price) : 0, [activeOrder]);

  const statusObj = useMemo(() => {
    if (activeOrder?.status) {
      return getOrderStatusByValue(activeOrder.status);
    }
    return {};
  }, [activeOrder]);

  return (
    <Popover
      content={
        <div style={{ width: 1000 }}>
          <Spin
            indicator={<LoadingOutlined spin />}
            size="large"
            spinning={isLoading}
          >
            <OrderItems

              activeOrder={activeOrder}
              total={total}
              baseUrl={baseUrl}
              isShort
            />
          </Spin>
        </div>
      }
      title={
        <Flex align="center">
          <StatusBadge
            value={statusObj?.status}
            color={statusObj?.status}
            label={statusObj?.label}
          />
          <Text strong>{"# " + (activeOrder?.order_no || id)}</Text>
        </Flex>
      }
      trigger="hover"
      placement="topLeft"
      open={isPopoverOpen}
      onOpenChange={handleOpenChange}
      {...rest}
    >
      <Text className="text-primary" underline>
        {children}
      </Text>
    </Popover>
  );
}
import { API_ENDPOINT1, API_ENDPOINT2, API_ENDPOINT3, API_ENDPOINT4, API_ENDPOINT5, __local__ } from "../../../constants/varibales";
import { getBaseUrlFromStore, getUserDataFromStore } from "../../../store/util";

const SiteOptions = [
    { id: 1, value: API_ENDPOINT1, label: 'Fixmybrokenscreen.co.uk', brand: 'FMBS' },
    { id: 2, value: API_ENDPOINT2, label: 'iWatchRepair.co.uk', brand: 'IWATCH' },
    { id: 3, value: API_ENDPOINT3, label: 'iPad-Repair.co.uk', brand: 'IPAD' },
    { id: 4, value: API_ENDPOINT4, label: 'MacbookFix.co.uk', brand: 'MACBOOK' },
    { id: 5, value: API_ENDPOINT5, label: 'Trade Fixmybrokenscreen.co.uk', brand: 'BUSINESS' },
];

const dropdownConditionals = ['/', '/dashboard', '/orders', '/orders/urgent', '/orders/collection', '/direct-orders'];

function getCurrentEmail(value) {
    const { email } = getUserDataFromStore();

    const emailUsername = email.split("@")[0];
    let emailDomain = value.split("//")[1].split("/")[0];

    if (__local__) {
        emailDomain = (emailDomain.split('.')[0] === 'trade' ? 'fixmybrokenscreen' : emailDomain.split('.')[0]) + '.co.uk';
    } else {
        let url = new URL(value);
        let host = url.host.replace(/^www\./, '');
        let subdomain = host.split('.')[0];
        if (subdomain === 'trade') {
            host = host.replace(/^trade\./, '');
        }
        emailDomain = host
    }

    return emailUsername + '@' + emailDomain
}

function getCurrentPwd() {
    const baseUrl = getBaseUrlFromStore();

    let currentPwd = null;
    if (SiteOptions[SiteOptions.length - 1].value === baseUrl) {
        if (hasRole('admin'))
            currentPwd = process.env.REACT_APP_DEFAULT_AUTH_SUPER_ADMIN_PASSWORD
        else if (hasRole('editor'))
            currentPwd = process.env.REACT_APP_DEFAULT_AUTH_TEAM_PASSWORD
    } else {
        if (hasRole('super-admin'))
            currentPwd = process.env.REACT_APP_DEFAULT_AUTH_SUPER_ADMIN_PASSWORD
        else if (hasRole('admin'))
            currentPwd = process.env.REACT_APP_DEFAULT_AUTH_ADMIN_PASSWORD
        else if (hasRole('editor'))
            currentPwd = process.env.REACT_APP_DEFAULT_AUTH_TEAM_PASSWORD
    }

    return currentPwd;
}

function hasRole(role_name) {
    const { roles } = getUserDataFromStore();
    return roles.some(role => role.name === role_name);
}

function extractDomain(url) {
    // Known second-level domains
    const secondLevelDomains = ['co', 'com', 'gov', 'org', 'net'];

    const urlObj = new URL(url);
    const hostname = urlObj.hostname;
    const parts = hostname.split('.');

    // Regular expression to check if the hostname is an IP address
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    // Check if the hostname is an IP address, return null if true
    if (ipRegex.test(hostname)) {
        return null;
    }

    // Get the last two parts of the domain
    const lastTwo = parts.slice(-2);

    // Check if the second-to-last part is a known second-level domain
    if (parts.length > 1 && secondLevelDomains.includes(lastTwo[0])) {
        // Combine the second-to-last and last parts
        return lastTwo.join('.');
    } else {
        // Return just the last part
        return lastTwo[lastTwo.length - 1];
    }
}

function getSiteName(url) {
    const siteName = SiteOptions.find(site => site.value === url);
    return siteName?.label;
}

export {
    SiteOptions,
    dropdownConditionals,
    getCurrentEmail,
    getCurrentPwd,
    hasRole,
    extractDomain,
    getSiteName
};
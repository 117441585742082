import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    urgentOrder: 0,
};

const menuBadgeCountKeySlice = createSlice({
    name: 'menuBadgeCount',
    initialState,
    reducers: {
        setCountKey: (state, action) => {
            state[action.payload.key] += 1;
        },
    },
});

export const { setCountKey } = menuBadgeCountKeySlice.actions;
export default menuBadgeCountKeySlice.reducer;

import appApi from "../index";
import qs from 'qs';

const endpoint = "condition-description"

const conditionList = (url = null, params) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/condition-list`, { params: { ...qs.parse(params) } })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};


const list = (url = null, type) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}`, {
        params: {
          type
        },
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const createCondition = (url = null, formData) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}`, formData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const deleteCondition = (url = null, formData, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .delete(`/${endpoint}/${id}`, formData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};
    
export default {
  list,
  conditionList,
  createCondition,
  deleteCondition
};
import React, { useEffect, useState, useMemo } from 'react';
import { Button, Checkbox, Col, Drawer, Form, Input, InputNumber, Row, Space, Typography, message } from 'antd';

import { printAFile } from '../../../util/doPrint';
import RecycleOrdersApi from '../../../apis/order-management/recycle-order/RecycleOrdersApi';
import OrdersApi from '../../../apis/order-management/repair-order/OrdersApi';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

const { Text } = Typography;

export default function DeviceChecker({ visible, handleCancel, baseUrl, maskClosable = false, activeOrderId, setKey }) {
    const [form] = Form.useForm();
    const validateMessages = {
        required: '${label} is required!',
    };

    const [loadingObj, setLoadingObj] = useState({
        saveAndPrint: false,
        saveOnly: false,
        print: false,
        testPrint: false
    });

    const [modelLoading, setModelLoading] = useState(false);
    const [activeOrder, setActiveOrder] = useState(null);

    const fetchOrder = async () => {
        setModelLoading(true);
        try {
            const order = await RecycleOrdersApi.getOne(baseUrl, activeOrderId);
            setActiveOrder(order);
        } catch (error) {
            message.error('Something went wrong!');
        }
        finally {
            setModelLoading(false);
        }
    };

    useEffect(() => {
        fetchOrder();
    }, []);

    const handlePrint = async (copies = 3) => {
        const hide = message.loading('label is printing..', 0);
        const order = await RecycleOrdersApi.getOne(baseUrl, activeOrderId);
        if (order) {
            await printAFile(baseUrl, order, () => {
                if (maskClosable) handleCancel()
            }, () => {
                hide()
            }, copies);
        }
    }

    const handleItemsCheckAndPrintLabel = async (values, loadingProp, isPrintable) => {
        setLoadingObj(prevLoadingObj => ({ ...prevLoadingObj, [loadingProp]: true }));
        try {
            const response = await OrdersApi.ItemsChecker(baseUrl, activeOrder.id, {
                devices: values.devices ?? null,
                orderType: "recycle-orders",
            });

            if (response) {
                setKey(prevState => prevState + 1);
                message.success(`${activeOrder?.order_no + " -" ?? ""} Device Checker is updated`);
                handleCancel();

                // when change 'processing' order status, should be trigger label print.
                if (isPrintable) {
                    handlePrint();
                }
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
        finally {
            setLoadingObj(prevLoadingObj => ({ ...prevLoadingObj, [loadingProp]: false }));
        }
    }

    const onFinish = async (values) => {
        handleItemsCheckAndPrintLabel(values, 'saveAndPrint', true);
    };

    const onSaveOnly = async () => {
        const values = await form.validateFields();
        handleItemsCheckAndPrintLabel(values, 'saveOnly', false);
    };

    const formInitialValues = useMemo(() => {
        let orderItemsChecker = activeOrder?.check_order_items || [];
        if (orderItemsChecker.length > 0) {
            return orderItemsChecker.map((item) => ({
                model: item.product_name,
                qty: item.qty,
                short_note: item.short_note,
                is_printable: true
            }));
        }

        let productIds = [];
        let products = [];

        // order_repair is for repair orders, order_items is for recycling orders and items is for direct orders
        let orderItems = activeOrder?.order_repair || activeOrder?.order_items || activeOrder?.items || [];
        orderItems.forEach((item) => {
            if (activeOrder?.order_repair || activeOrder?.items) {
                let idOrName = activeOrder.items ? item.model_name : item.products?.id;
                if (!productIds.includes(idOrName)) {
                    let product = {
                        product_name: item.model_name || item.products?.alias || item.products?.model,
                        short_note: item.short_note || '',
                        condition: item.condition?.name || '',
                        network: item.network?.name || '',
                        order_qty: item.qty || item.quantity,
                        services: [item.service],
                        fault_note: item.fault_note ? [item.fault_note] : [],
                    };

                    productIds.push(idOrName);
                    products.push({ product });

                } else {
                    let productIndex = productIds.indexOf(idOrName);
                    let product = products[productIndex].product;
                    product.services.push(item.service);

                    if (item.fault_note) product.fault_note.push(item.fault_note);
                }
            }
            else if (activeOrder?.order_items) {
                if (item.product && item.quantity && item.condition) {
                    // recycle order items: Keep all items for printing.
                    let product = {
                        product_name: item.product.alias || item.product.model,
                        short_note: item.short_note || '',
                        condition: item.condition?.name || '',
                        network: item.network?.name || '',
                        order_qty: item.quantity,
                        services: [item.service],
                        fault_note: item.fault_note ? [item.fault_note] : [],
                    };
                    products.push({ product });
                }
            }
        });

        const orderCheckItems = [];
        products.forEach((product) => {
            const { product_name, short_note, condition, network, fault_note, services, order_qty } = product.product;
            const deviceName = product_name?.alias || product_name?.model || product_name || '';
            const formattedFaultNote = fault_note?.filter(Boolean).join(' + ');
            const formattedServices = services.map(service => service?.alias)?.filter(Boolean).join(' + ');
            const formattedDetails = [formattedFaultNote, formattedServices, condition, network, short_note]?.filter(Boolean).join(' / ');

            orderCheckItems.push({
                model: deviceName,
                qty: order_qty,
                short_note: (formattedDetails ? formattedDetails : ''),
                is_printable: true
            });
        });

        return orderCheckItems;
    }, [activeOrder]);

    return (
        <Drawer
            title={<Text># {activeOrder ? activeOrder.order_no + " -" : ""} Print Label</Text>}
            open={visible}
            onClose={handleCancel}
            onOk={onFinish}
            confirmLoading={modelLoading}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            size='large'
            placement='left'
            maskClosable={maskClosable}
            destroyOnClose
            extra={
                <Space>
                    <Button size='small' onClick={handleCancel}>Cancel</Button>
                </Space>
            }
        >
            {activeOrder &&
                <Form
                    {...layout}
                    form={form}
                    id="device-checker"
                    name="nest-messages"
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    initialValues={{
                        devices: formInitialValues
                    }}
                >

                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <p style={{ marginBottom: 8, }}>Devices:</p>
                            <Form.List name="devices">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }, index) => (
                                            <Space
                                                key={key}
                                                style={{
                                                    display: 'flex',
                                                }}
                                                align="start"
                                            >
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'is_printable']}
                                                    valuePropName="checked"
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <Checkbox />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'model']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Missing model',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Model Name" />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'qty']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Missing qty',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber size="large" min={1} placeholder="Qty" />
                                                </Form.Item>

                                                <Form.Item {...restField} name={[name, 'short_note']}>
                                                    <Input.TextArea placeholder="Short Note" />
                                                </Form.Item>
                                                <MinusCircleOutlined className='pt-3' onClick={() => remove(name)} />
                                            </Space>
                                        ))}
                                        <Form.Item label="">
                                            <Button
                                                size='middle'
                                                shape="round"
                                                type="dashed"
                                                onClick={() => add({ model: '', qty: 1, network_id: null, condition_id: null, short_note: '' })}
                                                icon={<PlusOutlined />}
                                            >
                                                Add field
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                        {activeOrder &&
                            <Col key="footer-key">
                                <Button
                                    form='device-checker'
                                    key="submit"
                                    type="primary"
                                    htmlType="submit"
                                    className='mr-2'
                                    loading={loadingObj.saveAndPrint}
                                >
                                    Update & Print
                                </Button>

                                <Button
                                    form='device-checker'
                                    key="updateOnly"
                                    loading={loadingObj.saveOnly}
                                    onClick={onSaveOnly}
                                    className='mr-2'
                                >
                                    Update
                                </Button>
                            </Col>
                        }
                    </Row>
                </Form>
            }
        </Drawer>
    )
}

import { Button, Card, Space } from 'antd';
import React, { useState } from 'react';

import DeviceChecker from '../DeviceChecker';

export default function GroupOptions({ activeOrder, baseUrl, activeOrderId, services, setKey, visibleDeviceChecker, setVisibleDeviceChecker }) {
    const [loadingObj, setLoadingObj] = useState({
        printLabel: false,
        exportDelivery: false,
    });

    return (
        <Card className='mb-4'>
            <Space align="center" size="middle" wrap>
                <Button onClick={() => setVisibleDeviceChecker(true)}>Print Label</Button>
            </Space>

            {visibleDeviceChecker &&
                <DeviceChecker
                    visible={visibleDeviceChecker}
                    handleCancel={() => setVisibleDeviceChecker(false)}
                    activeOrderId={activeOrderId}
                    setKey={setKey}
                    baseUrl={baseUrl}
                    services={services}
                    maskClosable
                />
            }
        </Card>
    )
}

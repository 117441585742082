import { ExclamationCircleOutlined, UploadOutlined, EyeOutlined } from '@ant-design/icons';

import { Button, Col, Form, Modal, Row, message, Drawer, Typography, Input, Upload } from 'antd';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ConditionApi from '../../../../apis/settings/ConditionApi';

dayjs.extend(utc)
dayjs.extend(timezone)

const { confirm } = Modal;
const { Text } = Typography;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickEdit({ visible, handleCancel, activeCondition, handleReset, productCategory, roles, setIndex }) {
    const validateMessages = {
        required: '${label} is required!',
    };


    const baseUrl = useSelector((state) => state.auth.base_url)
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [isImageFront, setIsImageFront] = useState(false);
    const [isImageBack, setIsImageBack] = useState(false);

    const onFinish = async (values) => {
        try {

            const formData = new FormData();
            for (const key in values) {
                if (key === 'image_front' && values[key]) {
                    formData.append(key, values[key].file);
                } else if (key === 'image_back' && values[key]) {
                    formData.append(key, values[key].file);
                } else {
                    formData.append(key, values[key]);
                }
            }

            if (activeCondition?.id) {
                formData.append('id', activeCondition.id);
            }

            try {
                const updateCondition = await ConditionApi.createCondition(baseUrl, formData);
                if (updateCondition) {
                    message.success('Condition is Updated!');
                    setIndex(prevIndex => prevIndex + 1);
                    form.resetFields();
                    handleReset();
                }
            } catch (error) {
                message.error('Something went wrong!');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleCondition = async () => {
        const formData = new FormData();
        formData.append('selectedRowIds', [activeCondition.id]);

        try {
            const deleteCondition = await ConditionApi.deleteCondition(baseUrl, formData, activeCondition.id);
            if (deleteCondition) {
                message.success('Condition is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form.resetFields();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleCondition();
            },
            onCancel() {
                //
            },
        });
    }

    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }

    return (
        <Drawer
            push={false}
            key="notification-details-model"

            title={<Row key="row"
                align="middle"
                justify="space-between"
            >
                <Col md={24} className='d-flex' style={{ justifyContent: 'space-between' }}>
                    <Text>Condition Form</Text>

                    <div className='d-flex'>
                        <Button key="cancel" onClick={handleCancel}>Cancel</Button>
                        <Button
                            className='mx-2'
                            form='condition-update'
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {activeCondition?.id ? "Update" : "Create"}
                        </Button>
                    </div>
                </Col>
            </Row>}
            open={visible}
            onClose={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={1000}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeCondition && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>
                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form}
                id="condition-update"
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
                initialValues={{
                    name: activeCondition?.name,
                }}
            >
                <Row gutter={[24, 0]}>
                    <Col span={23}>
                        <Row gutter={[24, 0]}>
                            <Col span={8}>
                                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                                    <Input placeholder='Enter Name' />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                {activeCondition?.image_front && !isImageFront ? (
                                    <>
                                        <div>
                                            <Text>Image Front</Text><br />
                                            <Link
                                                to={{ pathname: activeCondition.image_front }}
                                                target="_blank"
                                                className="btn-sign-in"
                                            >
                                                view <EyeOutlined />
                                            </Link>
                                            <Button type="primary" className='mx-4' onClick={() => setIsImageFront(true)}>Change</Button>
                                        </div>
                                    </>
                                ) : (
                                    <Form.Item name="image_front" label="Image Front" >
                                        <Upload
                                            listType="picture"
                                            maxCount={1}
                                            beforeUpload={() => false}
                                        >
                                            <Button type="primary" icon={<UploadOutlined />}>
                                                Upload
                                            </Button>
                                        </Upload>
                                    </Form.Item>
                                )}
                            </Col>
                            <Col span={8}>
                                {activeCondition?.image_back && !isImageBack ? (
                                    <>
                                        <div>
                                            <Text>Image Back</Text><br />
                                            <Link
                                                to={{ pathname: activeCondition.image_back }}
                                                target="_blank"
                                                className="btn-sign-in"
                                            >
                                                view <EyeOutlined />
                                            </Link>
                                            <Button type="primary" className='mx-4' onClick={() => setIsImageBack(true)}>Change</Button>
                                        </div>
                                    </>
                                ) : (
                                    <Form.Item name="image_back" label="Image Back" >
                                        <Upload
                                            listType="picture"
                                            maxCount={1}
                                            beforeUpload={() => false}
                                        >
                                            <Button type="primary" icon={<UploadOutlined />}>
                                                Upload
                                            </Button>
                                        </Upload>
                                    </Form.Item>
                                )}
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}

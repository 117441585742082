import React from 'react';

import './App.css';

import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

import './styles/Web.scss';

import ComponentRenderer from './routes/ComponentRenderer';

function App() {
  return (
    <div className="App">
      <ComponentRenderer />
    </div>
  );
}

export default App;

// Pay type
const getAllPayType = [
    { value: 0, label: "diagnostic" },
    { value: 1, label: "paypal" },
    { value: 2, label: "klarna" },
    { value: 3, label: "stripe" },
];

// Pay
const getAllPayStatus = [
    { value: 0, label: "UNPAID", color: "error" },
    { value: 1, label: "SENT", color: "success" },
    { value: 2, label: "PAID", color: "success" },
    { value: 3, label: "CANCELLED", color: '#ccc' },
];

const getPayStatusByValue = (id) => (getAllPayStatus.find(({ label }) => (label === id)));

export {
    getAllPayType,
    getPayStatusByValue,
};